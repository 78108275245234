import { createSignal, JSX, createResource, createEffect, Show, onCleanup } from 'solid-js';

import { Context, FirebaseV9 } from '@bitiotic/bitiotic';

import { signOut } from './auth';
import { Lobby } from '@bitiotic/numbet';
import { StandardButton } from './StandardButton';

function AccountSection(props: {
    title: string,
    children: JSX.Element,
}): JSX.Element {
    return <div class="m-3 border p-2 p-99">
        <p class="text-large font-bold">{props.title}</p>
        {props.children}
    </div>;
}

export function AccountSettings(props: {
    ctx: Context,
    fbauth: FirebaseV9.FirebaseAuth,
    lobby: Lobby,
}): JSX.Element {
    const [getFBUser] = createResource(() => {
        const fbUser = props.fbauth.currentUser();
        return {
            email: fbUser?.email(),
            providerId: fbUser?.providerId(),
            providerDisplayName: fbUser?.displayName(),
        };
    });

    // Keep DB and UI display names separate to avoid race conditions at load-time
    const [getDisplayNameDB, setDisplayNameDB] = createSignal<string>('');
    const [getDisplayNameUI, setDisplayNameUI] = createSignal<string>('');
    const [getEditNameMode, setEditNameMode] = createSignal<boolean>();

    createEffect(() => {
        const ctx = props.ctx.newCtx('setupListen');
        const fbUser = props.fbauth.currentUser();
        if (fbUser) {
            const playerPath = Lobby.pathForUser(fbUser);

            const cleanListenPlayer = props.lobby.listenToPlayer(props.ctx, playerPath,
                (globalPlayer) => {
                    setDisplayNameDB(globalPlayer.displayName);
                },
                err => {
                    ctx.log('Ignoring error in listen setup:', err);
                });

            onCleanup(() => {
                ctx.log('Cleaning up player listener for Account tab');
                cleanListenPlayer();
            });
        }
    });

    function validateDisplayName(n: string | undefined): string | null {
        if (!n) {
            return null;
        }
        return n;
    }

    return <>
        <div class="text-xl font-bold">Account Settings</div>

        <AccountSection title="From Login">
            <div>
                Login name: "{getFBUser()!.email}" (via {getFBUser()!.providerId})
            </div>
            <div>
                Display Name: {getFBUser()!.providerDisplayName}
            </div>
        </AccountSection>
        <AccountSection title="Numbet Configuration">
            <div>
                <Show when={getEditNameMode()}>
                    <label for="displayName-input">Display Name:</label>
                    <input
                        id="displayName-input"
                        required
                        minlength="2"
                        maxlength="128"
                        class="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline invalid:border-red-600"
                        type="text"
                        value={getDisplayNameDB()}
                        onInput={ev => { setDisplayNameUI(ev.target.value); }}
                    />
                    <StandardButton
                        color="green"
                        onClick={() => {
                            const fbuser = props.fbauth.currentUser();
                            const displayName = validateDisplayName(getDisplayNameUI());
                            if (displayName && fbuser) {
                                setDisplayNameDB('...'); // will re-load from network
                                /*async*/props.lobby.setPlayerDisplayName(props.ctx, fbuser, displayName);
                                setEditNameMode(false);

                            }
                        }}
                    >Save</StandardButton>
                </Show>
                <Show when={!getEditNameMode()}>
                    Display Name: {getDisplayNameDB()}
                    <StandardButton color="green" onClick={() => { setEditNameMode(true); }}>Edit</StandardButton>
                </Show>

            </div>
        </AccountSection >
        <div>
            <StandardButton
                color="red"
                onClick={() => { signOut(props.ctx, props.fbauth); }}>Sign Out</StandardButton>
        </div>
    </>;
}


