import { Context, FirebaseV9 } from '@bitiotic/bitiotic';
import { Game, GameOptions, Lobby, Persistent } from '@bitiotic/numbet';
import { JSX, Show, createSignal } from 'solid-js';
import { GameLink } from './Routing';
import { StandardButton } from './StandardButton';

export function getEmailShareHref(ctx: Context, gameId: string | null): string {
    if (gameId === null) {
        const subj = encodeURIComponent('Check out Numbets!');
        return `mailto:?subject=${subj}`;
    }

    const gameURL = getGameURL(ctx, gameId);
    const subj = encodeURIComponent('Join my Numbets game!');
    const body = encodeURIComponent(`
        Click this link to join the game: ${gameURL}
    `);
    return `mailto:?subject=${subj}&body=${body}`;
}

export function getGameURL(ctx: Context, gameId: string | null): string {
    const u = new URL(window.location.href);

    if (gameId === null) {
        u.pathname = '/';
    } else {
        u.pathname = `game/${gameId}`;
    }

    return u.href;
}

function strToNum(s: string | undefined, defaultNum: number): number {
    if (s === undefined) {
        return defaultNum;
    }

    const n = Number(s);
    if (isNaN(n)) {
        return defaultNum;
    }
    return n;
}


export function NewGame(props: {
    ctx: Context,
    lobby: Lobby,
    firestore: FirebaseV9.Firestore,
    playerPath: 'unknown' | Persistent.PlayerPath,
}): JSX.Element {
    const [getNewlyCreatedGamePath, setNewlyCreatedGamePath] = createSignal<Persistent.GamePath | null>(null);
    const [getValidationError, setValidationError] = createSignal<string>('');
    const [getCreateDisabled, setCreateDisabled] = createSignal<boolean>(false);
    const [getRoundCountUI, setRoundCountUI] = createSignal<string>('');

    const gameOptions = new GameOptions(props.ctx);

    function createNewGame(): Promise<unknown> {
        setValidationError('');

        const ctx = props.ctx.newCtx('newGame');
        const pth = props.playerPath;
        if (!pth || pth === 'unknown') {
            throw ctx.newError('Should not be able to CreateGame without a current player');
        }

        const roundStr = getRoundCountUI();
        const roundCt = strToNum(roundStr, -1);

        if (roundCt <= 0) {
            setValidationError(`Invalid round count: "${roundStr}"`);
            return Promise.resolve();
        }

        if (roundCt > 20) {
            setValidationError('Round count too high (maximum 20).');
            return Promise.resolve();
        }

        setCreateDisabled(true);

        gameOptions.setRoundsPerGame(ctx, roundCt);

        return Game.startNew(ctx, pth, gameOptions, props.firestore)
            .then(g => {
                ctx.log('New game', g.getGamePath(), 'created');
                setNewlyCreatedGamePath(g.getGamePath());
            })
            .catch(err => {
                setValidationError(`Unhandled error: ${err}`);
            })
            .finally(() => {
                setTimeout(() => setCreateDisabled(false),
                    250);
            });
    }

    setRoundCountUI(`${gameOptions.roundsPerGame(props.ctx)}`);

    return <>
        <div class="font-bold text-xl">New Game</div>
        <div class="p-3">
            Number of rounds (number of questions to ask):
            <input
                id="new-game-max-round-count"
                autocomplete="off"
                minlength="1"
                maxlength="3"
                class="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline invalid:border-red-600"
                type="number"
                value={getRoundCountUI()}
                onInput={ev => { setRoundCountUI(ev.target.value); }}></input>
            <div class="px-4 text-red-500">{getValidationError()}</div>
        </div>
        <StandardButton
            color="green"
            disabled={getCreateDisabled()}
            onClick={createNewGame}>Create New Game</StandardButton>
        <Show when={getNewlyCreatedGamePath()}>
            <div class="font-bold text-xl">Created Game</div>
            <div class="p-3">
                Link:&nbsp;
                <GameLink ctx={props.ctx} gamePath={getNewlyCreatedGamePath()}>{getNewlyCreatedGamePath()}</GameLink>
            </div >
        </Show>
    </>;
}