import { JSX } from 'solid-js';

import { Context } from '@bitiotic/bitiotic';

function Link(props: {
    ctx: Context,
    href: string,
    children: JSX.Element,
}): JSX.Element {
    return <a
        class="font-bold text-blue-700 hover:text-blue-400 visited:text-violet-900"
        href={props.href}
    >{props.children}</a>;
}

export function About(props: {
    ctx: Context,
}): JSX.Element {
    return <div class="mx-8 md:max-w-3xl">
        <p class="p-1">
            Numbets is a companion application for North Star Game's
            {' '} <Link ctx={props.ctx} href="https://www.northstargames.com/products/wits-wagers-deluxe-edition">Wits & Wagers</Link>.
            Numbets provides distributed, digital tracking of players, their guesses and their overall scores.
            You will need to provide the players, the questions, and
            a way of sharing questions.
        </p>
        <p class="p-1">
            You will need to provide a source of trivia questions.
            If you don't have a copy of Wits & Wagers questions handy, you can use
            any trivia questions where the answer is a number and is greater than zero.
        </p>
        <p class="p-1">
            The user that starts a Numbets game can invite other players by sending the game's URL to them.
            This user is the "manager" of the game,
            and is responsible for asking the question, closing the guessing round,
            and entering the answer into the Numbets app.
        </p>
        <p class="p-1">
            Start a new game by clicking <b>New Game</b> above.  Give the URL of the newly created
            game to everyone who is playing, and wait for them to log into Numbets and join the game.
        </p>
        <p class="p-1">
            To play a round on Numbets, ask the question, and have everyone enter it on their screen
            as their "Guess".  Once everyone has confirmed their guess, the manager of the game
            should click the "Close Guessing" button.  In the betting phase, each player may bet
            a $100 chip and a $200 chip on which range they think the right answer is in.  The manager
            will submit the correct answer to close the betting round and determine which users have
            won what.
        </p>
        <h2>Known shortcomings:</h2>
        <ul class="list-disc mx-12">
            <li>No units on answers.  All answers are "unitless" so make sure everyone agrees on
                the units (e.g., meters, miles, year, feet, inches, etc).</li>
            <li>No fine-grained dates in answers.  Only years.  You can use decimal points for months if you
                want?  But its probably easier to skip questions with a year/month/day answer. </li>
            <li>No answers with "B.C." or "A.D." on the year.  Skip questions that may have plausible
                answers on either side of B.C. and A.D.</li>
        </ul>
    </div>;
}