import { JSX } from 'solid-js/jsx-runtime';
import { Show, For, createSignal, onCleanup, createEffect, Setter, createMemo, Accessor } from 'solid-js';


import { Context, anyerror } from '@bitiotic/bitiotic';
import { Game, Persistent, Round, PlayerBetMap, PlayAction, RoundWinnings } from '@bitiotic/numbet';

import { DebugObj } from './DebugObj';
import { DebugErr } from './DebugErr';
import { GamePlayerListener, StyledPNum } from './GamePlayerListener';
import { StandardButton } from './StandardButton';
import { SectionHeader } from './SectionHeader';
import { currentPlayerGlowShadow, currentPlayerGlowRadius } from './PlayersList';

// Inverted PlayerBetMap, by spot, not by player
interface BetSpotMap {
    [betSpot: string]: {
        [player: string]: Persistent.BettingChipPath[],
    }
}

// Invert map "player->chip->spot" into "spot->chip->player" map
function invertBetMap(betMap: PlayerBetMap): BetSpotMap {
    const newMap: BetSpotMap = {};

    Object.entries(betMap).forEach(val => {
        const [player, chips] = val;
        Object.entries(chips).forEach(cVal => {
            const [chip, betSpot] = cVal;
            const spotMap = newMap[betSpot] = (newMap[betSpot] || {});
            const chips = spotMap[player] = (spotMap[player] || []);
            chips.push(chip as Persistent.BettingChipPath);
        });
    });

    return newMap;
}


type BoxStatus = 'emph' | 'de-emph' | 'stale';

function statusColor(status: BoxStatus): { [clz: string]: boolean } {
    if (status === 'stale') {
        return { 'border-grey-500': true };
    }
    if (status === 'de-emph') {
        return { 'border-green-700': true };
    }
    return { 'border-green-500': true };
}


function BoxBorder(props: {
    status: BoxStatus,
    children: JSX.Element
}): JSX.Element {
    return <div
        class="border-2 p-2 w-fill"
        classList={statusColor(props.status)}
    >{props.children}</div>;
}

function BetMultiplier(props: {
    val: number,
}): JSX.Element {
    return <span>
        &times;{props.val}
    </span>;
}

function GuessInput(props: {
    ctx: Context,
    game: Game,
    round: Round,
    playerPath: Persistent.PlayerPath,
    getLocalGuess: Accessor<string | null>,
    setLocalGuess: Setter<string | null>,
    setRejection: Setter<unknown>,
    disabled: boolean,
}): JSX.Element {
    function sendGuess(ctx: Context, round: Round, guessStr?: string): void {
        /* async */
        if (guessStr) {
            round.setGuess(ctx, props.playerPath, guessStr)
                .catch(err => {
                    props.setRejection(err);
                }).then(() => {
                    ctx.log('Server accepted guess, update local state');
                    props.setLocalGuess(guessStr);
                });
        }
    }

    function dynamicClasses(disabled: boolean, currentGuess: string | null): { [clz: string]: boolean } {
        if (disabled) {
            return { 'border-grey-500': true };
        }
        if (currentGuess === null) {
            return { 'border-green-700': true };
        }
        return { 'border-green-500': true };
    }

    const getEmph = createMemo<BoxStatus>(() => {
        if (props.disabled) {
            return 'stale';
        }
        const lg = props.getLocalGuess();
        if (lg === null || lg === '') {
            return 'emph';
        }
        return 'de-emph';
    });

    const getId = createMemo<string>(() => {
        const p = props.game.getGamePath();
        return `user-guess-${p}-${props.round.getRoundLabel(props.ctx)}`;
    });

    const [getGuessUI, setGuessUI] = createSignal<string>(props.getLocalGuess() ?? '');

    createEffect(() => {
        // Be sure to update local signal when parent's guess is cleared out ...
        setGuessUI(props.getLocalGuess() ?? '');
    })

    return <BoxBorder status={getEmph()}>
        <div class="flex w-fill justify-between">
            <div class="p-2 inline-block">
                <input
                    id={getId()}
                    name={getId()}
                    autocomplete="off"
                    minlength="1"
                    maxlength="8"
                    disabled={props.disabled}
                    placeholder="Your best guess"
                    class="appearance-none border rounded py-2 px-3 text-black-700 leading-tight focus:outline-none focus:shadow-outline invalid:border-red-600"
                    classList={dynamicClasses(props.disabled, props.getLocalGuess())}
                    type="number"
                    value={getGuessUI()}
                    onInput={ev => { setGuessUI(ev.target.value); }}
                />
            </div>
            <div class="py-2 inline-block">
                <StandardButton
                    disabled={props.disabled}
                    onClick={() => sendGuess(props.ctx, props.round, getGuessUI())}
                    color='green'
                >Guess</StandardButton>
            </div>
        </div>
    </BoxBorder>;
}

function AnswerInput(props: {
    ctx: Context,
    game: Game,
    round: Round,
    playerPath: Persistent.PlayerPath,
    getAnswerStr: Accessor<string>,
    setAnswerStr: Setter<string>,
    setRejection: Setter<unknown>,
    disabled: boolean
}): JSX.Element {
    function sendAnswer(ctx: Context, round: Round, answerStr?: string): Promise<boolean> {
        if (answerStr) {
            return round.resolveGuessesForAnswer(ctx, answerStr)
                .then(() => {
                    ctx.log(`Server accepted answer "${answerStr}".  Closing round.`);
                    return props.game.closeRound(ctx, round)
                        .then(() => true);
                }).catch(err => {
                    ctx.log('Error on resolving guesses in sendAnswer:', err);
                    props.setRejection(err);
                    return false;
                });
        }
        return Promise.resolve(false);
    }

    const getEmph = createMemo<BoxStatus>(() => {
        if (props.disabled) {
            return 'stale';
        }
        return 'emph';
    });

    const getId = createMemo<string>(() => {
        const p = props.game.getGamePath();
        return `user-answer-${p}-${props.round.getRoundLabel(props.ctx)}`;
    });

    return <BoxBorder status={getEmph()}>
        <div class="flex w-fill justify-between">
            <div class="p-2 inline-block">
                <input
                    id={getId()}
                    name={getId()}
                    autocomplete={getId()}
                    minlength="1"
                    maxlength="25"
                    disabled={props.disabled}
                    placeholder="The correct answer"
                    class="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline invalid:border-red-600"
                    type="number"
                    value={props.getAnswerStr()}
                    onInput={ev => { props.setAnswerStr(ev.target.value); }}
                />
            </div>
            <div class="py-2 inline-block">
                <StandardButton
                    disabled={props.disabled}
                    onClick={() => {
                        return sendAnswer(props.ctx, props.round, props.getAnswerStr())
                            .then(sent => {
                                if (sent) {
                                    props.ctx.log('Answer sent, disconnecting');
                                    // Disconnect to prevent multiple answers (?)
                                    //answerInput = undefined;
                                } else {
                                    props.ctx.log('Answer did not send ... leaving as-is');
                                }
                            });
                    }}
                    color='green'
                >Answer</StandardButton>
            </div>
        </div>
    </BoxBorder>;
}

function ChipBetButton(props: {
    ctx: Context,
    answerIsPresent: boolean,
    betIsHere: boolean, // bet is placed at this location
    betIsOnBoard: boolean, // bet is placed on the board anywhere
    onClick: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>
    children: JSX.Element,
}): JSX.Element {
    const common = 'font-semibold py-1 px-2 border-2 mx-1 rounded';
    const active = 'border-green-900 text-green-700 bg-transparent' +
        ' hover:border-transparent hover:bg-green-600 hover:text-green-100';
    const inact = 'text-stone-700 hover:text-stone border border-stone-500 bg-transparent';
    const redundant = 'border-stone-900 text-green-100 bg-green-600';

    function classes(): string {
        if (props.answerIsPresent) {
            if (props.betIsHere) {
                return common + ' ' + redundant;
            } else {
                return common + ' ' + inact + ' opacity-20';
            }
        } else if (props.betIsHere) {
            return common + ' ' + redundant;
        } else if (props.betIsOnBoard) {
            return common + ' ' + active + ' opacity-75';
        } else {
            return common + ' ' + active;
        }
    }

    return <button
        class={classes()}
        disabled={props.answerIsPresent}
        onClick={props.onClick}
    >{props.children}</button>;
}

function ReportMyChipWinnings(props: {
    ctx: Context,
    chipPath: Persistent.BettingChipPath,
    chipResults: Persistent.PlayerChipPayout | undefined,
    winComponents: number,
}): JSX.Element {
    if (!props.chipResults) {
        return <></>;
    }
    return <>
        <Show when={props.winComponents === 1}>
            <div class="inline-block mx-1">
                You bet ${props.chipResults.bet}
                {' '}<BetMultiplier val={props.chipResults.multiplier} />
                {' '}and won ${props.chipResults.payout}!
            </div>
        </Show>
        <Show when={props.winComponents > 1}>
            <div class="inline-block mx-1">
                ${props.chipResults.payout}
                {' '}for betting
                {' '}${props.chipResults.bet} with a
                {' '}<BetMultiplier val={props.chipResults.multiplier} />.
            </div>
        </Show>
    </>;
}


export function RoundTitle(props: {
    ctx: Context,
    game: Game,
    round: Round | null,
    getLocalGuess: () => string | null,
    myWinnings: Readonly<RoundWinnings> | null,
}): JSX.Element {
    function roundMofN(ctx: Context, game: Game, round: Round | null): JSX.Element {
        if (!round) {
            return <span></span>;
        }

        const rNum = round.getRoundNumber() + 1;
        const roundCt = game.getOptions().roundsPerGame(ctx);

        return <span>
            Round #{rNum} of {roundCt}
        </span>;
    }

    return <SectionHeader ctx={props.ctx}>
        {roundMofN(props.ctx, props.game, props.round)}
        <Show when={props.getLocalGuess() !== null}>
            &nbsp;- guess {props.getLocalGuess()}
        </Show>
        <Show when={props.myWinnings?.winnings}>
            &nbsp;- won ${props.myWinnings?.winnings}
        </Show>
        <Show when={props.myWinnings?.winnings === 0}>
            &nbsp;- won nothing
        </Show>
    </SectionHeader>;
}

function ResultsSectionHeader(props: {
    ctx: Context,
    playerPath: Persistent.PlayerPath,
    myWinnings: Readonly<RoundWinnings> | null,
    results: Readonly<Persistent.RoundResults> | null,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {
    // TODO: Make the "Results" title show "Scroll for results" when they're off the screen.
    // Look into https://primitives.solidjs.community/package/intersection-observer

    return <div class="flex justify-between">
        <Show when={props.results === null}>
            <div class="text-slate-400">Round Results</div>
        </Show>
        <Show when={props.results !== null}>
            <div>
                Round Results: Answer is {props.results?.answer}
            </div>
        </Show>
    </div>;
}

function PlusSign(_props: Record<string, never>): JSX.Element {
    return <span class="text-green-700 font-sans font-extrabold text-2xl w-4 inline-block text-center">+</span>;
}

function MinusSign(_props: Record<string, never>): JSX.Element {
    return <span class="font-sans font-extrabold text-2xl w-4 inline-block text-center">-</span>;
}

function ReportMyRoundResults(props: {
    ctx: Context,
    playerPath: Persistent.PlayerPath,
    myWinnings: Readonly<RoundWinnings> | null,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {
    function winningChips(winningBets: Persistent.PlayerPayout | undefined): Readonly<Persistent.BettingChipPath[]> {
        if (!winningBets) {
            return [];
        }
        return Object.keys(winningBets).map(k => Persistent.asBettingChipPath(k));
    }

    const [getWinComponents, setWinComponents] = createSignal<number>(0);

    createEffect(() => {
        const w = props.myWinnings;
        let components = 0;
        if (w) {
            if (w.winningGuess) {
                components += 1;
            }
            components += Object.keys(w.winningBets).length;
        }
        setWinComponents(components);
    });

    const noBets = createMemo(() => {
        const betPaths = (props.myWinnings?.winningBets || {});
        return Object.keys(betPaths).length === 0;
    });

    return <li>
        <Show when={!(props.myWinnings?.winnings)}>
            <span>
                You (<StyledPNum
                    ctx={props.ctx}
                    playerPath={props.playerPath}
                    gamePlayerListener={props.gamePlayerListener} />)
                won $0.
            </span>
            <ul class="list-inside marker:text-green-900 border border-green-900 ml-3 px-3 py-1">
                <li><MinusSign /> Your answer was not the best answer.</li>
                <li><MinusSign /> Your bets were not on the winning answer.</li>
            </ul>
        </Show>
        <Show when={(props.myWinnings?.winnings)}>
            <Show when={getWinComponents() > 0}>
                <span>
                    You (<StyledPNum
                        ctx={props.ctx}
                        playerPath={props.playerPath}
                        gamePlayerListener={props.gamePlayerListener} />)
                    won ${props.myWinnings?.winnings} from:
                </span>
            </Show>
            <ul class="list-inside marker:text-green-700 border border-green-700 ml-3 px-3 py-1">
                <Show when={props.myWinnings?.winningGuess}>
                    <Show when={props.myWinnings?.winningGuessDelta === 0}>
                        <li>
                            <PlusSign /> ${props.myWinnings?.winningGuessPayout}
                            &nbsp;for answering {props.myWinnings?.winningGuess} exactly!
                        </li>
                    </Show>
                    <Show when={props.myWinnings?.winningGuessDelta !== 0}>
                        <li>
                            <PlusSign /> ${props.myWinnings?.winningGuessPayout}
                            &nbsp;for the best guess ({props.myWinnings?.winningGuess}).
                        </li>
                    </Show>
                </Show>
                <For each={winningChips(props.myWinnings?.winningBets)}>
                    {chipPath => <li><PlusSign /><ReportMyChipWinnings
                        ctx={props.ctx}
                        chipPath={chipPath}
                        winComponents={getWinComponents()}
                        chipResults={props.myWinnings?.winningBets[chipPath]}
                    /></li>}
                </For>
                <Show when={noBets()}>
                    <li><MinusSign /> No bets on the winning answer.</li>
                </Show>
            </ul>
        </Show >
    </li >;
}


function ReportOthersResults(props: {
    ctx: Context,
    playerPath: Persistent.PlayerPath,
    results: Readonly<Persistent.RoundResults> | null,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {

    interface PlayerWin {
        playerPath: Persistent.PlayerPath,
        playerWin: number,
        correctGuess: boolean,
    }

    const guessPhrase = createMemo(() => {
        let ct = 0;
        const winningPlayers = Object.keys(props.results?.playerWinnings || {});
        winningPlayers.forEach(playerPath => {
            const correctGuess = Boolean(props.results?.winningGuesses?.[playerPath]);
            if (correctGuess) ct += 1;
        });

        if (ct > 1) {
            return 'tied for best guess';
        }
        return 'had the best guess';
    });

    const otherTotals = createMemo(() => {
        const totals: PlayerWin[] = [];
        const winningPlayers = Object.keys(props.results?.playerWinnings || {});
        winningPlayers.forEach(playerPath => {
            if (playerPath !== props.playerPath) {
                const correctGuess = Boolean(props.results?.winningGuesses?.[playerPath]);
                totals.push({
                    playerPath: Persistent.asPlayerPath(playerPath),
                    playerWin: props.results?.playerWinnings[playerPath] || 0,
                    correctGuess,
                });
            }
        });

        totals.sort((t1, t2) => t2.playerWin - t1.playerWin);

        return totals;
    });

    return <>
        <For each={otherTotals()}>
            {other => {
                return <li>
                    <StyledPNum
                        ctx={props.ctx}
                        playerPath={other.playerPath}
                        gamePlayerListener={props.gamePlayerListener}
                    /> won ${other.playerWin}
                    <Show when={other.correctGuess}>
                        &nbsp;and {guessPhrase()}
                    </Show>
                    .
                </li>;
            }}
        </For>
    </>;
}

function ReportRoundResults(props: {
    ctx: Context,
    playerPath: Persistent.PlayerPath,
    myWinnings: Readonly<RoundWinnings> | null,
    results: Readonly<Persistent.RoundResults> | null,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {

    return <div class="m-3 p-3 w-fit  mx-auto">
        <DebugObj
            _closed={true}
            _visible={false}
            myWinnings={props.myWinnings}
            results={props.results}
        />

        <Show when={(props.myWinnings === null) || (props.myWinnings.winnings === 0)}>
            <span>Round complete.</span>
        </Show>
        <Show when={(props.myWinnings !== null) && (props.myWinnings.winnings > 0)}>
            <span>Round complete!</span>
        </Show>
        <ul class="list-disc list-inside p-3 ml-3">
            <ReportMyRoundResults
                ctx={props.ctx}
                playerPath={props.playerPath}
                myWinnings={props.myWinnings}
                gamePlayerListener={props.gamePlayerListener}
            />
            <ReportOthersResults
                ctx={props.ctx}
                playerPath={props.playerPath}
                results={props.results}
                gamePlayerListener={props.gamePlayerListener}
            />
        </ul>
        <DebugObj
            myWinings={props.myWinnings}
            results={props.results}
        />
    </div>;
}

function WaitForGuesses(props: {
    ctx: Context,
    visible: boolean
    isManager: boolean,
    setRejection: Setter<unknown>,
    round: Round,
}): JSX.Element {
    function closeGuessing(ctx: Context, round: Round | null): void {
        ctx.assertNotNull(round, 'round must not be null');

        /*async*/
        round.layoutGuesses(ctx.newCtx('layout'))
            .catch(err => {
                props.setRejection(err);
            });
    }

    return <Show when={props.visible}>
        <Show when={props.isManager}>
            <div class="p-3 w-fill flex justify-center">
                <StandardButton
                    disabled={false}
                    onClick={() => closeGuessing(props.ctx, props.round)}
                    color="green"
                >End Guessing Phase</StandardButton>
            </div>
        </Show>
        <Show when={!props.isManager}>
            <div class="p-3">
                Waiting for Manager to close guessing ...
            </div>
        </Show>
    </Show>;
}

export function RoundBoard(props: {
    ctx: Context,
    game: Game,
    round: Round,
    playerPath: Persistent.PlayerPath,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {
    const players = props.game.getPlayerListCache(props.ctx); // immutable
    const playerPath = props.playerPath; // immutable
    const ctx = props.ctx.newCtx('RoundBoard'); // immutable
    const betSpots = props.round.getBetSpotPaths(ctx).sort(); // immutable
    const isManager = props.round.isManager(ctx, playerPath); // immutable

    // betChip paths is basically constant ...
    const [getBetChipPaths, setBetChipPaths] = createSignal<Readonly<Persistent.BettingChipPath[]>>([]);
    setBetChipPaths(props.round.getBettingChipPaths(props.ctx, props.playerPath));

    const [getReadyPlayers, setReadyPlayers] = createSignal<Readonly<Persistent.PlayerPath[]> | null>(null);
    const [getGuessPresence, setGuessPresence] = createSignal<Readonly<Persistent.PlayerPath[]> | null>(null);
    const [getLocalGuess, setLocalGuess] = createSignal<string | null>(null);
    const [getGuessLayout, setGuessLayout] = createSignal<Readonly<Persistent.GuessLayout> | null>(null);
    const [getBetMap, setBetMap] = createSignal<Readonly<PlayerBetMap>>({});
    const [getSpotMap, setSpotMap] = createSignal<BetSpotMap>({});
    const [getResults, setResults] = createSignal<Readonly<Persistent.RoundResults> | null>(null);
    const [getPlayAction, setPlayAction] = createSignal<PlayAction>('unknown');
    const [getPlayerWinnings, setPlayerWinnings] = createSignal<Readonly<RoundWinnings> | null>(null);
    const [getRejection, setRejection] = createSignal<null | unknown>(null);
    const [getAnswerStr, setAnswerStr] = createSignal<string>('');

    createEffect(() => registerListeners(ctx.newCtx('listeners'), props.round, playerPath));

    function registerListeners(ctx: Context, round: Round, currentPlayerPath: Persistent.PlayerPath): void {
        setReadyPlayers(null);
        setGuessPresence(null);
        setLocalGuess(null);
        setAnswerStr('');
        setGuessLayout(null);
        setBetMap({});
        setSpotMap({});
        setResults(null);
        setPlayerWinnings(null);
        setPlayAction('unknown');

        const cleanReadyListen = round.listenForReadyPlayers(ctx,
            (ctx, ready) => {
                setReadyPlayers(Array.from(ready.keys()));
                updatePlayAction(ctx, round);
            },
            err => setRejection(err)
        );

        // This is only for the manager of the game to do.  Regular users just wait for the
        // guess layout to show up.
        const cleanPresenceListen =
            isManager ? round.listenForGuessPresence(ctx,
                currentPlayerPath,
                (ctx, present, _newPlayers, myGuess) => {
                    setGuessPresence(Array.from(present.keys()));
                    if (myGuess !== null) {
                        setLocalGuess(myGuess);
                    }
                    updatePlayAction(ctx, round);
                },
                err => setRejection(err)
            ) : () => { /*no cleanup*/ };

        const cleanLayoutListen = round.listenForGuessLayout(ctx,
            (ctx, layout) => {
                setGuessLayout(layout);
                updatePlayAction(ctx, round);
            },
            err => setRejection(err)
        );

        const cleanBetListen = round.listenForPlayerBets(ctx,
            (ctx, bets, _updated) => {
                setBetMap({ ...bets }); // clone so SolidJS knows it changed
                setSpotMap(invertBetMap(bets));
                updatePlayAction(ctx, round);
            },
            err => setRejection(err)
        );

        const cleanResultListen = round.listenForRoundResults(ctx,
            (ctx, results) => {
                setResults({ ...results }); // clone for SolidJS
                if (results.answer === null) {
                    setAnswerStr('');
                } else {
                    setAnswerStr(`${results.answer}`);
                }
                updatePlayAction(ctx, round);
                /*async*/
                round.getPlayerWinnings(ctx, playerPath)
                    .then(winnings => {
                        setPlayerWinnings(winnings);
                        updatePlayAction(ctx, round);
                    })
                    .catch(err => {
                        setRejection(err);
                    });
            },
            err => {
                if (anyerror.causedByPermissionDenied(err)) {
                    ctx.log('RoundResults permission denied error, Ignoring:', err);
                    setRejection('Not allowed to see round details.');
                } else {
                    setRejection(err);
                }
            }
        );

        onCleanup(() => {
            ctx.log('RoundState cleanup for', round.instanceId, round.getRoundPath(ctx));
            cleanReadyListen();
            cleanLayoutListen();
            cleanResultListen();
            cleanPresenceListen();
            cleanBetListen();
        });
    }

    function updatePlayAction(ctx: Context, round: Round): void {
        // Expect the Round instance to have been updated by the listen callbacks.
        const action = round.getPlayerAction(ctx, playerPath);

        if (action === 'declare-ready') {
            // TODO: Supress this change if its already in flight, somehow?
            declareReady(ctx, round);
        } else {
            ctx.log('set playAction to', action, 'on round', round.getRoundPath(ctx));
            setPlayAction(action);
        }
    }

    // Check status after sending a guess
    createEffect(() => {
        getLocalGuess(); // ignore value, just listen for changes
        updatePlayAction(props.ctx, props.round);
    });

    function declareReady(ctx: Context, round: Round | null): void {
        ctx.assertNotNull(round, 'round must not be null');

        /*async*/
        ctx.log('delcaring I am ready to play round');
        round.setPlayerIsReady(ctx, playerPath)
            .catch(err => {
                setRejection(err);
            });

        // XXX oustanding set-ready promise
    }

    ctx.log('RoundBoard for', props.round.instanceId, props.round.getRoundNumber(), props.round.getRoundPath(ctx));
    setRejection(null);

    return <div>
        <RoundTitle
            ctx={props.ctx.newCtx('RoundTitle')}
            game={props.game}
            round={props.round}
            getLocalGuess={getLocalGuess}
            myWinnings={getPlayerWinnings()}
        />

        <DebugErr label="Round Rejection" err={getRejection()} />

        <Show when={getPlayAction() === 'unknown'}>
            Waiting for Round to load ...
        </Show>

        <div class="round-actions p-3 max-w-screen-sm mx-auto">
            {/* Step 1: Send "i'm ready" signal to Firebase (implictly done) */}

            {/* Step 2: Wait for question (out of band) and submit guess */}
            <GuessInput
                ctx={ctx.newCtx('GuessInput')}
                game={props.game}
                round={props.round}
                playerPath={playerPath}
                getLocalGuess={getLocalGuess}
                setLocalGuess={setLocalGuess}
                setRejection={setRejection}
                disabled={getPlayAction() !== 'guess' && getPlayAction() !== 'wait-for-guess-layout'} />

            {/* Step 2b: When done waiting for guesses, manager closes guessing. */}
            <WaitForGuesses
                ctx={ctx.newCtx('WaitForGuess')}
                visible={['wait-for-guess-layout', 'guess'].includes(getPlayAction())}
                isManager={isManager}
                round={props.round}
                setRejection={setRejection}
            />

            {/* Step 3: Show the table of guesses and betting spots */}
            <Show when={['close-round', 'bet-missing-chips'].includes(getPlayAction())}>
                <div class="w-fill">
                    <table class="table-fixed w-fill mx-auto">
                        <tbody>
                            <tr class="font-lg">
                                <th class="w-12">Guess</th>
                                <th class="w-8">By</th>
                                <th class="w-8">{/*separator column*/}</th>
                                <th class="">Bets</th>
                                <th class="w-12">&times;</th>
                            </tr>
                            <For each={betSpots}>
                                {/* Step4: Capture betting chips on the layed out guesses */}
                                {spot =>
                                    <TableRowTwoHalfs
                                        ctx={ctx.newCtx(`row-${spot}-top`)}
                                        spot={spot}
                                        round={props.round}
                                        playerPath={props.playerPath}
                                        layout={getGuessLayout()}
                                        bets={getSpotMap()}
                                        chipMap={getBetMap()[props.playerPath] || {}}
                                        results={getResults()}
                                        betChips={getBetChipPaths()}
                                        setRejection={setRejection}
                                        gamePlayerListener={props.gamePlayerListener}
                                    />
                                }
                            </For>
                        </tbody>
                    </table>
                </div>
            </Show>

            {/* Step 4b: Implicitly close betting when manager provides the answer */}
            <Show when={isManager}>
                <AnswerInput
                    ctx={ctx.newCtx('AnsInput')}
                    game={props.game}
                    round={props.round}
                    playerPath={props.playerPath}
                    setRejection={setRejection}
                    setAnswerStr={setAnswerStr}
                    getAnswerStr={getAnswerStr}
                    disabled={getPlayAction() !== 'bet-missing-chips'} />
            </Show>

            {/* Step 5: Show the result of the round (on the layout). */}
        </div>

        <SectionHeader ctx={ctx}>
            <ResultsSectionHeader
                ctx={ctx}
                playerPath={props.playerPath}
                results={getResults()}
                myWinnings={getPlayerWinnings()}
                gamePlayerListener={props.gamePlayerListener}
            />
        </SectionHeader>

        <Show when={getPlayAction() === 'close-round'}>
            <ReportRoundResults
                ctx={ctx.newCtx('roundResult')}
                myWinnings={getPlayerWinnings()}
                playerPath={props.playerPath}
                results={getResults()}
                gamePlayerListener={props.gamePlayerListener}
            />
        </Show>

        <DebugObj
            _closed={true}
            _visible={false}
            action={getPlayAction()}
            ready={getReadyPlayers()}
            guessPresent={getGuessPresence()}
            layout={getGuessLayout()}
            bets={getBetMap()}
            spotMap={getSpotMap()}
            game={props.game.getGamePath()}
            players={players}
            isMgr={isManager}
            round={props.round.getRoundPath(ctx)}
            roundNum={props.round.getRoundNumber()}
            betChips={getBetChipPaths()}
        />

    </div >;
}

function TableRowTwoHalfs(props: {
    ctx: Context,
    round: Round,
    playerPath: Persistent.PlayerPath,
    spot: Persistent.BetSpotPath,
    layout: Readonly<Persistent.GuessLayout> | null,
    bets: Readonly<BetSpotMap>,
    chipMap: Readonly<{ [chipPath: string]: Persistent.BetSpotPath }>,
    betChips: Readonly<Persistent.BettingChipPath[]>,
    setRejection: Setter<unknown>,
    results: Readonly<Persistent.RoundResults> | null,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {

    function getValStr(spot: Persistent.BetSpotPath): string {
        if (spot === Persistent.BetSpot_Underflow) {
            return '<';
        }

        const guesses: Persistent.Guesses = (props.layout && props.layout[props.spot]) || {};
        const player0 = Object.keys(guesses)[0];
        return (player0 && guesses[player0]?.rawGuess) || '';
    }

    function rowStyle(
        spot: Persistent.BetSpotPath,
        valStr: string,
        results: Readonly<Persistent.RoundResults> | null): Record<string, string | string> {

        const fgColor = 'pink';
        let bgColor = 'transparent';
        let boxShadow = '0px 0px 0px 0xp #000';
        let vis: 'collapse' | 'visible' = 'collapse';

        if (results && results.answer) {
            if (results.winningSpotPath === spot) {
                // winning row
                bgColor = '#5f4';
                // boxShadow: xOffset yOffset blurSize spreadSize color
                boxShadow = '0px 0px 8px 4px ' + bgColor;
            } else {
                // losing row
                bgColor = 'transparent';
            }
        }

        if (spot === Persistent.BetSpot_Underflow) {
            vis = 'visible';
        }

        if (valStr !== '') {
            vis = 'visible';
        }

        return {
            'text-color': fgColor,
            'background-color': bgColor,
            'visibility': vis,
            'box-shadow': boxShadow,
        };
    }

    // Is given bet chip on the given path
    function betChipIsAtPath(_ctx: Context, bets: Readonly<BetSpotMap>,
        spotPath: Persistent.BetSpotPath, playerPath: Persistent.PlayerPath,
        chipPath: Persistent.BettingChipPath): boolean {
        const spotChips = bets[spotPath];
        const chipList = spotChips?.[playerPath];
        return chipList?.includes(chipPath) ?? false;
    }

    function makeBet(ctx: Context, round: Round | null,
        playerPath: Persistent.PlayerPath, spotPath: Persistent.BetSpotPath,
        chipPath: Persistent.BettingChipPath): void {
        ctx.assertNotNull(round, 'round must not be null');

        /* async */
        round.setBet(ctx, playerPath, chipPath, spotPath)
            .catch(err => {
                props.setRejection(err);
            });
    }

    function betChipIsPlayed(_ctx: Context,
        chipMap: { [chipPath: string]: Persistent.BetSpotPath },
        chipPath: Persistent.BettingChipPath): boolean {

        if (chipMap[chipPath] === undefined) {
            return false;
        }
        return true;
    }

    function isLosingRow(_ctx: Context, results: Readonly<Persistent.RoundResults> | null,
        currentSpot: Persistent.BetSpotPath): boolean {
        if (results && results.answer) {
            const winningSpotPath = results.winningSpotPath;
            return (winningSpotPath !== currentSpot);
        }
        return false; // not a losing row until some row is the winner
    }

    function getAnswerStr(_ctx: Context, results: Readonly<Persistent.RoundResults> | null,
        currentSpot: Persistent.BetSpotPath): string {
        if (results && results.answer) {
            const winningSpotPath = results.winningSpotPath;
            if (winningSpotPath === currentSpot) {
                return `${results.answer}`;
            }
        }
        return '';
    }

    function spotMultiplier(spot: Persistent.BetSpotPath): number {
        const betSpot = props.round.getBetSpot(props.ctx, spot);
        return betSpot.payoutMultiplier;
    }

    /* <border> <guess> <guesser> <bet100> <bet200> <multiplier> */
    /* <border> <answer> <skip> <other's bets> <skip> */
    return <>
        <tr style={rowStyle(props.spot, getValStr(props.spot), props.results)}>
            <td classList={
                {
                    'text-center': true,
                    'font-bold': props.spot !== Persistent.BetSpot_Underflow,
                    'align-top': true,
                    'p-1': true,
                }
            }>
                {getValStr(props.spot)}
            </td>
            <td rowspan="2" class="align-top p-1">
                <BetSpotGuessers
                    ctx={props.ctx}
                    spot={props.spot}
                    round={props.round}
                    layout={props.layout}
                    currentPlayerPath={props.playerPath}
                    gamePlayerListener={props.gamePlayerListener}
                />
            </td>
            <td rowspan="2" class="content-start">
                <div class="bg-gray-300 pt-4 pb-4 h-16 w-2 self-start">
                </div>
            </td>
            <td>
                {/* for making bets */}
                <div class="break-inside-avoid whitespace-nowrap">
                    <For each={props.betChips}>
                        {chipPath =>
                            <ChipBetButton
                                ctx={props.ctx}
                                answerIsPresent={Boolean(props.results && props.results.answer)}
                                betIsHere={betChipIsAtPath(props.ctx, props.bets, props.spot,
                                    props.playerPath, chipPath)}
                                betIsOnBoard={betChipIsPlayed(props.ctx, props.chipMap, chipPath)}
                                onClick={() => makeBet(props.ctx, props.round, props.playerPath, props.spot, chipPath)}
                            >
                                <div class="px-2">
                                    Bet {props.round.getBettingChip(props.ctx, chipPath).label}
                                </div>
                            </ChipBetButton>
                        }
                    </For>
                </div>
            </td>
            <td rowspan="2">
                <div class="text-center">
                    <BetMultiplier val={spotMultiplier(props.spot)} />
                </div>
            </td>
        </tr>

        <tr style={rowStyle(props.spot, getValStr(props.spot), props.results)}>
            <td class="text-center text-green-600 font-extrabold">
                {getAnswerStr(props.ctx, props.results, props.spot)}
            </td>
            {/* Upper row "guesser" cell flows into this cell */}
            {/* Upper row "separator" cell flows into this cell */}
            <td>
                {/* made bets */}
                <BetSpotMadeBets
                    ctx={props.ctx}
                    spot={props.spot}
                    round={props.round}
                    layout={props.layout}
                    bets={props.bets}
                    currentPlayerPath={props.playerPath}
                    losingRow={isLosingRow(props.ctx, props.results, props.spot)}
                    gamePlayerListener={props.gamePlayerListener}
                />
            </td>
            {/* Upper row "multiplier" cell flows into this cell */}
        </tr>
    </>;
}

function BetSpotMadeBets(props: {
    ctx: Context,
    spot: Persistent.BetSpotPath,
    round: Round,
    layout: Readonly<Persistent.GuessLayout> | null,
    bets: Readonly<BetSpotMap>,
    currentPlayerPath: Persistent.PlayerPath,
    losingRow: boolean,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {
    type OneBet = {
        player: Persistent.PlayerPath,
        playerName: string,
        color: string,
        label: string,
        value: number,
        textDecoration: 'line-through' | 'none',
    };

    const bets = createMemo<OneBet[]>(() => {
        const betObjs: OneBet[] = [];
        const playerBets = props.bets[props.spot];
        if (playerBets) {
            Object.entries(playerBets).forEach(([playerStr, chips]) => {
                const player = Persistent.asPlayerPath(playerStr);
                const nameGetter = props.gamePlayerListener.getNameSignal(props.ctx, player);
                const colorGetter = props.gamePlayerListener.getColorSignal(props.ctx, player);

                const deco: 'line-through' | 'none' = props.losingRow ? 'line-through' : 'none';
                const oneBet = {
                    player,
                    playerName: nameGetter(),
                    color: props.losingRow ? 'grey' : colorGetter(),
                    textDecoration: deco,
                };

                let value = 0;
                chips.forEach(chip => {
                    value += props.round.getBettingChip(props.ctx, chip).value;
                });

                betObjs.push({
                    ...oneBet,
                    value,
                    label: `$${value}`,
                });
            });
        }
        return betObjs.sort((a, b) => b.value - a.value);
    });

    const styleDisplay = 'inline-block';
    const styleMargin = '0.2em';

    return <div class="whitespace-nowrap">
        <Show when={bets().length === 0}>
            <div style={{
                display: styleDisplay,
                margin: styleMargin,
            }}>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>
        </Show>
        <For each={bets()}>
            {bet => {
                return <div style={{
                    color: bet.color,
                    display: styleDisplay,
                    padding: styleMargin,
                    'box-shadow': (props.currentPlayerPath === bet.player) ? currentPlayerGlowShadow : undefined,
                    'border-radius': (props.currentPlayerPath === bet.player) ? currentPlayerGlowRadius : undefined,
                    'text-decoration': bet.textDecoration,
                }}>
                    <div class="text-center">
                        <div>{bet.label}</div>
                        <StyledPNum
                            ctx={props.ctx}
                            playerPath={bet.player}
                            gamePlayerListener={props.gamePlayerListener}
                        />
                    </div>
                </div>;
            }}
        </For >
    </div>;
}

function BetSpotGuessers(props: {
    ctx: Context,
    round: Round,
    spot: Persistent.BetSpotPath,
    currentPlayerPath: Persistent.PlayerPath,
    layout: Readonly<Persistent.GuessLayout> | null,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {
    const players = createMemo<Persistent.PlayerPath[]>(() => {
        const guessObjs: Persistent.PlayerPath[] = [];
        if (props.layout) {
            const rawGuesses = props.layout[props.spot];
            if (rawGuesses) {
                Object.entries(rawGuesses).forEach(([playerStr]) => {
                    const player = Persistent.asPlayerPath(playerStr);
                    guessObjs.push(player);
                });
            }
        }
        return guessObjs;
    });

    function divStyle(guesser: Persistent.PlayerPath): Record<string, string> {
        if (props.currentPlayerPath === guesser) {
            return {
                'box-shadow': currentPlayerGlowShadow,
                'border-radius': currentPlayerGlowRadius,
            };
        }
        return {};
    }


    return <For each={players()}>
        {playerPath => {
            return <div style={divStyle(playerPath)}>
                <StyledPNum
                    ctx={props.ctx}
                    playerPath={playerPath}
                    gamePlayerListener={props.gamePlayerListener} />
            </div>;
        }}
    </For>;
}

