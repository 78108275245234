import { JSX } from 'solid-js/jsx-runtime';
import { createSignal, For, Match, Switch } from 'solid-js';

function stringify(obj: unknown) {
    function jsonstr(obj: unknown) {
        function replacer(key: string, value: unknown): unknown {
            if (key === 'firestore') {
                return '<firestore>';
            } else if (key.startsWith('_')) {
                return '<private>';
            }
            return value;
        }

        return JSON.stringify(obj, replacer, 4);
    }

    if (obj instanceof Set) {
        const keys = jsonstr(Array.from(obj.keys()));
        return `Set<${keys}>`;
    }

    if (obj instanceof Map) {
        const content = jsonstr(Array.from(obj.entries()));
        return `Map<${content}>`;
    }
    return jsonstr(obj);
}

const fixedProps = ['_closed', '_visible'];

export function DebugObj(props: {
    _closed?: boolean,
    _visible?: boolean,
    [propName: string]: unknown
}): JSX.Element {
    const closedDefault = props._closed ?? false;

    const [getClosed, setClosed] = createSignal<boolean>(closedDefault);

    return <div style="background:#E2E2E2" class={props._visible ? 'visible' : 'hidden'}>
        <button class="font-bold" onClick={() => setClosed(h => !h)}>+</button>
        <span hidden={getClosed()}> Debug:
            <div class="m-3">
                <dl class="list-disc list-inside">
                    <For each={Object.entries(props).filter(([k]) => !fixedProps.includes(k))}>
                        {([k, v]) => <>
                            <dt class="font-bold"><code>{k}:</code></dt>
                            <dd class="m-2">
                                <Switch>
                                    <Match when={v === undefined}>
                                        <i>undefined</i>
                                    </Match>
                                    <Match when={v === null}>
                                        <i>null</i>
                                    </Match>
                                    <Match when={true}>
                                        <pre>{stringify(v)}</pre>
                                    </Match>
                                </Switch>
                            </dd>
                        </>}
                    </For>
                </dl>
            </div>
        </span>
    </div>;
}
