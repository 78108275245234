import { JSX } from 'solid-js/jsx-runtime';

import { Context, FirebaseV9 } from '@bitiotic/bitiotic';
import { Game, Lobby, Persistent } from '@bitiotic/numbet';

import { DebugObj } from './DebugObj';
import { createEffect, createSignal, For, onCleanup, Show } from 'solid-js';
import { GameLink } from './Routing';

type GameListEntry = { gamePath: Persistent.GamePath, game: Persistent.Game }

const [getGamesList, setGamesList] = createSignal<GameListEntry[]>([]);

function watchGames(
    ctx: Context,
    firestore: FirebaseV9.Firestore,
    playerPath: Persistent.PlayerPath | 'unknown'): void {

    if (playerPath === 'unknown') {
        setGamesList([]);
        return;
    }

    const unsub = Game.gameCollection(ctx, firestore)
        .onSnapshotWhere(`players.${playerPath}`, '!=', null,
            games => {
                const gameList: GameListEntry[] = [];
                games.forEach(game => {
                    const gamePath = Persistent.asGamePath(game.id());
                    ctx.log('Found game', game.ref().debugString(), 'at', gamePath);
                    const data = game.data();
                    if (data) {
                        gameList.push({
                            gamePath,
                            game: data,
                        });
                    }
                });
                setGamesList(gameList);
            },
            err => {
                throw ctx.chainError(err, 'watching for games');
            });

    onCleanup(() => {
        unsub();
    });
}

/** Render the games/ page */
export function GameList(props: {
    lobby: Lobby,
    ctx: Context,
    firestore: FirebaseV9.Firestore,
    currentGamePath: Persistent.GamePath | null | undefined | 'unknown',
    playerPath: 'unknown' | Persistent.PlayerPath,
}): JSX.Element {
    createEffect(() => {
        watchGames(props.ctx.newCtx('watch'), props.firestore, props.playerPath);
    });

    return <div>
        <Show when={props.playerPath === 'unknown'}>
            <p>unknown playerpath</p>
        </Show>

        <div class="font-bold text-xl">Current Game</div>
        <Show when={props.currentGamePath === null}>
            <i>No current game</i>
        </Show>
        <Show when={props.currentGamePath !== null}>
            Game <GameLink ctx={props.ctx} gamePath={props.currentGamePath}>{props.currentGamePath}</GameLink>.
        </Show>

        <div class="font-bold text-xl">Other Games</div>

        <DebugObj
            currentGame={props.currentGamePath}
            playerPath={props.playerPath}
            gameList={getGamesList()}
        />
        <p>
            Numbets current does not support querying the list of available Games.
            You must get Game URLs from the game's creator.
        </p>
        <For each={getGamesList()} fallback={<div></div>}>
            {(game) => {
                function isCompleted(g: Persistent.Game) {
                    return g.completed === true;
                }

                return <div>
                    <GameLink ctx={props.ctx} gamePath={game.gamePath}>
                        {game.gamePath}
                    </GameLink>
                    <Show when={isCompleted(game.game)}>(completed)</Show>
                </div>;
            }}
        </For>
    </div>;
}