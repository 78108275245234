import { JSX } from 'solid-js/jsx-runtime';
import {
    Show, Switch, Match, createSignal, onCleanup, createEffect, createMemo, createResource, Setter, For
} from 'solid-js';

import { Context, anyerror } from '@bitiotic/bitiotic';
import { Game, Lobby, Persistent, Round } from '@bitiotic/numbet';

import { DebugObj } from './DebugObj';
import { DebugErr } from './DebugErr';
import { RoundBoard } from './RoundBoard';
import { PlayersList } from './PlayersList';
import { GamePlayerListener, StyledPNum } from './GamePlayerListener';
import { StandardButton } from './StandardButton';
import { SectionHeader } from './SectionHeader';
import { PlayerGameWinnings } from '@bitiotic/numbet/src/Game';
import { getGameURL } from './NewGame';

function noopUnsub() { /*noop*/ }

let prevRoundUnsub = noopUnsub;

function JoinDialog(props: {
    ctx: Context,
    currentPlayerPath: Persistent.PlayerPath,
    game: Game,
    setRejection: Setter<unknown>,
}): JSX.Element {

    function registerJoinListener(ctx: Context, game: Game) {
        const joinCleanup = game.listenForJoinRequest(ctx,
            (playerPath, reqDoc) => {
                const g = game;

                ctx.log('join request', playerPath);
                // Add Players to the UI

                g.addPlayer(ctx, playerPath, true, reqDoc)
                    .catch(err => {
                        ctx.log('Async error on addPlayer:', err);
                        props.setRejection(err);
                    });
            },
            err => props.setRejection(err));
        onCleanup(() => joinCleanup());
    }

    createEffect(() => registerJoinListener(props.ctx.newCtx('joinListen'), props.game));

    // TODO: Actually let the caller see/approve the join request.  Just auto-join for now.
    return <div></div>;
}

function GameCompletedSummary(props: {
    ctx: Context,
    currentPlayerPath: Persistent.PlayerPath,
    game: Game,
    gamePlayers: ReadonlyArray<Persistent.PlayerPath> | null;
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {

    interface PlayerWinnings {
        playerPath: Persistent.PlayerPath,
        winnings: PlayerGameWinnings,
        place: number,
    }

    interface WinningsSummary {
        players: PlayerWinnings[];
    }

    const [getWinnings, setWinnings] = createSignal<Readonly<WinningsSummary> | null>(null);

    // Convert Game results into a sorted list of PlayerWinnings objects
    createEffect(() => {
        const result: WinningsSummary = {
            players: [],
        };

        /*async*/
        props.game.forEachPlayer(props.ctx, playerPath => {
            return props.game.getPlayerWinnings(props.ctx, playerPath).then(winnings => {
                result.players.push({
                    playerPath,
                    winnings,
                    place: 0,
                });
            });
        }).then(() => {
            result.players.sort((a, b) => b.winnings.winnings - a.winnings.winnings);

            // Assign places (same place for ties)
            let place = 0;
            let prevWinnings: number | null = null;
            let wasTied = false;
            for (const entry of result.players) {
                if ((prevWinnings === null) || (entry.winnings.winnings < prevWinnings)) {
                    place = place + 1;
                    if (wasTied) {
                        place = place + 1;
                        wasTied = false;
                    }
                } else {
                    wasTied = true;
                }
                entry.place = place;
                prevWinnings = entry.winnings.winnings;
            }

            setWinnings(result);
        });
    });

    function liClass(place: number): string {
        if (place === 1) {
            return 'text-2xl';
        }
        if (place === 2) {
            return 'text-lg';
        }
        return '';
    }

    function liStyle(playerWinnings: Readonly<PlayerWinnings>): Record<string, string> {
        if (playerWinnings.playerPath === props.currentPlayerPath) {
            return {
                'box-shadow': '0 0 8px 4px #eab308',
            };
        }
        return {};
    }


    function winPrefix(place: number): JSX.Element {
        if (place === 1) {
            return <span>1st place!</span>;
        }
        if (place === 2) {
            return <span>2nd place</span>;
        }
        if (place === 3) {
            return <span>3rd place</span>;
        }
        return <></>;
    }

    return <>
        <SectionHeader ctx={props.ctx}>
            Game Results
        </SectionHeader>
        <div class="m-3 flex flex-col w-fill mx-auto">
            <Show when={getWinnings() === null}>
                Loading ...
            </Show>
            <Show when={getWinnings() !== null}>
                <For each={getWinnings()!.players}>
                    {playerWinnings => {
                        return <div
                            class={'mx-auto ' + liClass(playerWinnings.place)}
                            style={liStyle(playerWinnings)}>
                            <div class="p-2">
                                {winPrefix(playerWinnings.place)}&nbsp;
                                <StyledPNum
                                    ctx={props.ctx}
                                    playerPath={playerWinnings.playerPath}
                                    gamePlayerListener={props.gamePlayerListener} />
                                &nbsp;won ${playerWinnings.winnings.winnings}
                            </div>
                        </div>;
                    }}
                </For>
            </Show>
        </div>
    </>;
}

export function GameBoard(props: {
    lobby: Lobby,
    game: Game,
    ctx: Context,
    playerPath: 'unknown' | Persistent.PlayerPath
}): JSX.Element {
    if (props.playerPath === 'unknown') {
        return <p>unknown playerpath</p>;
    }

    const lctx = props.ctx.newCtx('gameListen');
    const getGamePlayerListener = createMemo(() => new GamePlayerListener(lctx, props.lobby, props.game, err => {
        // XXX do something better with errors
        throw lctx.chainError(err, 'from GamePlayers listeners');
    }));

    // The "round" object changes too frequently
    // (spammy updates from Firebase because of the schema),
    // so don't rebuild the UI directly from this.
    const [getCurrRound, setCurrRound] = createSignal<Round | null>(null);

    const [getGameState, setGameState] = createSignal<'unknown' | 'running' | 'completed'>('unknown');
    const [getRejection, setRejection] = createSignal<null | unknown>(null);
    const [getGamePlayers, setGamePlayers] = createSignal<Readonly<Persistent.PlayerPath[]> | null>(null);
    const [getWantNextRound, setWantNextRound] = createSignal<boolean>(false);
    const [getNextRoundReady, setNextRoundReady] = createSignal<boolean>(false);

    // Cached signals based on the state of the round (see setNewCurrRound)
    const [getRoundIsUnset, setRoundIsUnset] = createSignal<boolean>(true);
    const [getRoundIsFinished, setRoundIsFinished] = createSignal<boolean>(false);

    // Manager is constant, so no setter needed
    const [getIsManager] = createResource<boolean>(() => {
        if (props.playerPath === 'unknown') {
            return Promise.resolve(false);
        }
        return props.game.isManager(props.ctx, props.playerPath);
    });


    createEffect(() => registerListeners(props.ctx.newCtx('gListen'), props.game,
        Persistent.asPlayerPath(props.playerPath)));

    function registerListeners(ctx: Context, game: Game, playerPath: Persistent.PlayerPath) {
        const initialRound = game.getCurrentRound(ctx); // from cache on Game
        ctx.log('Game', game.getGamePath(), 'completed:', game.isCompleted(), 'curRound:', initialRound);

        setGamePlayers(game.getPlayerListCache(ctx));

        if (game.isCompleted()) {
            ctx.log('gameState for', game.getGamePath(), 'is "completed" at initialization');
            setGameState('completed');
        } else {
            setGameState('running');
        }

        setWantNextRound(true);
        const setNewRnd = setNewCurrRound(ctx, initialRound);
        ctx.assertTrue(setNewRnd,
            'Set currRound to', initialRound, 'rejected.');

        const completedCleanup = game.listenForGameCompleted(ctx,
            () => {
                ctx.log('gameState for', game.getGamePath(), 'is "completed"');
                setGameState('completed');
                // XXX explicitly disconnect listeners?  Or let them wind up on their own?
            },
            err => setRejection(err));
        onCleanup(() => completedCleanup());

        const newRoundCleanup = game.listenForNextRound(ctx, playerPath,
            r => {
                ctx.log('new round', r.getRoundPath(ctx), 'in', game.getGamePath());
                setNewCurrRound(ctx, r);
            },
            err => setRejection(err)
        );
        onCleanup(() => newRoundCleanup());

        const npCleanup = game.listenForNewPlayers(ctx,
            players => {
                setGamePlayers(players);
            },
            err => {
                ctx.log('Ignoring failure on new player update:', err);
            });
        onCleanup(() => npCleanup());
    }

    // Manager only.  Create the new round and publish it to everyone.
    function makeNextRound(ctx: Context, prevRound: Round | null): Promise<unknown> {
        ctx.assertNotNull(prevRound, 'Previous round must be defined');

        setWantNextRound(true);

        return props.game.newRound(ctx, prevRound.getRoundNumber() + 1)
            .then(r => {
                setNewCurrRound(ctx, r);
            })
            .catch(err => {
                setRejection(err);
            });
    }

    let delayedRound: Round | null = null; // XXX should be a signal?

    // Non-manager indicates they're ready for the next round (clicked "Join Next Round")
    function readyForNextRound(ctx: Context, round: Round | null): void {
        ctx.assertNotNull(round, 'Prevous round must be defined');

        setWantNextRound(true);

        // If we queued up a delayed round update, make that visible now
        if (delayedRound !== null) {
            if (setNewCurrRound(ctx.newCtx('delayed'), delayedRound)) {
                delayedRound = null;
            }
        }

    }

    /**
     * Set the given round as the 'Current' round, and figure out what state its in.  This Round object
     * comes from the server whenever its ready (i.e., independent of "wantNextRound").
     *
     * Return true if the new round was switched to.
     */
    function setNewCurrRound(ctx: Context, round: Round | null): boolean {
        if ((getCurrRound() !== null) && (round !== getCurrRound()) && (getWantNextRound() === false)) {
            /*
             * If current round is at the "results" phase, wait for the user to ACK
             * wanting the next round before installing the new one.
             */
            ctx.log('New round', round?.getRoundPath(ctx), 'delayed while looking at current round results');
            delayedRound = round;
            return false;
        }

        setCurrRound(round);

        prevRoundUnsub();
        prevRoundUnsub = noopUnsub;

        delayedRound = null;
        setRoundIsUnset(round === null);
        setRoundIsFinished(false);
        setNextRoundReady(false);
        setWantNextRound(false);

        if (round !== null) {
            const fin = round.isFinished(ctx);

            setRoundIsFinished(fin);

            if (!fin) {
                setGameState('running');

                // listen for round to get to be completed
                prevRoundUnsub = round.listenForRoundResults(ctx,
                    () => {
                        setNewCurrRound(ctx, round);
                    },
                    err => {
                        if (anyerror.causedByPermissionDenied(err)) {
                            setRejection('Not allowed to see results.');
                        } else {
                            setRejection(err);
                        }
                    });
            }
        }
        return true;
    }

    function assertCurrRound(ctx: Context, r: Round | null): Round {
        ctx.assertNotNull(r, 'Caller should prevent null Round from getting here');
        // ctx.log('assertCurRound: id:', r.instanceId, 'path:', r.getRoundPath(ctx));
        return r;
    }

    function endGameEarly(ctx: Context, game: Game, round: Round | null): void {
        ctx.assertNotNull(round, 'Caller should prevent null Round from getting here');

        /*async*/
        game.closeRound(ctx, round).then(() => {
            return game.setGameOver(ctx);
        });
    }

    setRejection(null);

    return <div>
        <DebugObj
            _closed={true}
            _visible={false}
            playerPath={props.playerPath}
            options={props.game.getOptions()}
            gamePath={props.game.getGamePath()}
            players={getGamePlayers()}
            gameState={getGameState()}
        />
        <Switch>
            <Match when={getGameState() === 'unknown'}>
                Loading Game ...
            </Match>
            <Match when={getGameState() === 'running' || getGameState() === 'completed'}>
                <SectionHeader ctx={props.ctx}>
                    Players
                </SectionHeader>
                <PlayersList
                    ctx={props.ctx.newCtx('plist')}
                    game={props.game}
                    currentPlayerPath={props.playerPath}
                    gamePlayers={getGamePlayers()}
                    round={getCurrRound()}
                    onManagerDevice={getIsManager() || false}
                    gamePlayerListener={getGamePlayerListener()}
                />
                <div>
                    <Show when={getIsManager()}>
                        <Show when={getGameState() !== 'completed'}>
                            <JoinDialog
                                ctx={props.ctx.newCtx('joindialog')}
                                currentPlayerPath={props.playerPath}
                                game={props.game}
                                setRejection={setRejection}
                            ></JoinDialog>
                        </Show>
                    </Show>
                    <Show when={getRoundIsUnset()}>
                        <SectionHeader ctx={props.ctx}>
                            Game
                        </SectionHeader>

                        <Show when={getIsManager()}>
                            <p class="p-2">
                                Once everyone has joined, you may start the first round.
                            </p>
                            <p class="p-2">
                                Share <a
                                    class="bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white"
                                    href={getGameURL(props.ctx, props.game.getGamePath())}
                                >this link</a> to invite others to this game.
                            </p>
                            <StandardButton
                                onClick={() => props.game.newRound(props.ctx, 0)}
                                color='green'
                            >Start First Round</StandardButton>
                        </Show>
                        <Show when={!getIsManager()}>
                            <p class="p-2">
                                Waiting for Manager to start first round ...
                            </p>
                        </Show>
                        <SectionHeader ctx={props.ctx}>
                            <div class="text-slate-400">Results</div>
                        </SectionHeader>
                    </Show>
                    <Show when={!getRoundIsUnset()}>
                        <RoundBoard
                            ctx={props.ctx.newCtx('RBrd')}
                            game={props.game}
                            round={assertCurrRound(props.ctx, getCurrRound())}
                            playerPath={props.playerPath}
                            gamePlayerListener={getGamePlayerListener()}
                        />
                        <Show when={getGameState() !== 'completed'}>
                            <DebugObj
                                _visible={false}
                                _closed={true}
                                roundNum={getCurrRound()?.getRoundNumber()}
                                roundPath={getCurrRound()?.getRoundPath(props.ctx)}
                                mgr={getIsManager()}
                                finishedMemo={getRoundIsFinished()}
                                roundIsFinished={getCurrRound()?.isFinished(props.ctx)}
                                roundAction={getCurrRound()?.getPlayerAction(props.ctx, props.playerPath)}
                                wantNextRound={getWantNextRound()}
                                nextRoundReady={getNextRoundReady()}
                                delayedRound_var={delayedRound}
                            />

                            <div class="flex p-3 justify-between w-fill">
                                <Show when={getIsManager()}>
                                    <Show when={getRoundIsFinished()}>
                                        <StandardButton
                                            onClick={() => makeNextRound(props.ctx, getCurrRound())}
                                            disabled={!getRoundIsFinished()}
                                            color='green'
                                        >Create Next Round</StandardButton>
                                    </Show>
                                    <StandardButton
                                        onClick={() => endGameEarly(props.ctx, props.game, getCurrRound())}
                                        color='red'
                                    >End Game Early</StandardButton>
                                </Show>
                                <Show when={!getIsManager() && getRoundIsFinished()}>
                                    <Show when={getWantNextRound() === true}>
                                        <p class="p-2">
                                            Waiting for Manager to start next Round ...
                                        </p>
                                    </Show>
                                    <Show when={getWantNextRound() === false}>
                                        <StandardButton
                                            onClick={() => readyForNextRound(props.ctx, getCurrRound())}
                                            disabled={!getRoundIsFinished()}
                                            color='green'
                                        >Join Next Round</StandardButton>
                                    </Show>
                                </Show>
                            </div>
                        </Show>
                    </Show>
                    <Show when={getGameState() === 'completed'}>
                        <GameCompletedSummary
                            ctx={props.ctx.newCtx('complete')}
                            game={props.game}
                            currentPlayerPath={props.playerPath}
                            gamePlayers={getGamePlayers()}
                            gamePlayerListener={getGamePlayerListener()}
                        />
                    </Show>
                </div>
            </Match >
        </Switch >
        <DebugErr label="GameBoard Error" err={getRejection()} />
    </div >;

}
