
import { JSX } from 'solid-js/jsx-runtime';
import { For } from 'solid-js';

function errMsg(err: unknown): string {
    if (typeof err === 'string') {
        return err;
    }

    if (typeof err !== 'object') {
        return `Non-object: ${err}`;
    }

    if (err === undefined || err === null) {
        return '';
    }

    if (!(err instanceof Error)) {
        return `Non-Error: ${err}`;
    }

    const msg = err.message;

    if (!msg) {
        return '<no message>';
    }
    if (typeof msg !== 'string') {
        return `<message is not a string: ${msg}>`;
    }
    return msg;
}

function stackLines(err: unknown): string[] {
    if (!err || !(err instanceof Error) || !err.stack) {
        return [];
    }
    return err.stack.split('\n');
}

interface ValKey {
    val: string,
    key: string,
}

interface RawErr {
    [key: string]: unknown,
}

function otherProps(err: unknown): ValKey[] {
    if (err === null) {
        return [];
    }

    if (typeof err === 'string') {
        return [];
    }

    if (typeof err !== 'object') {
        return [{ key: '__invalid__', val: 'Not an object' }];
    }

    // Hoop jumping to get the "rawErr[key]" to not trigger a Typescript warning
    const rawErr: RawErr = err as unknown as RawErr;

    const result: ValKey[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in err) { // enumerable, plus inherited enumerable
        if (!['message', 'stack', 'toString'].includes(key)) {
            const val: unknown = rawErr[key];
            result.push({
                key,
                val: `${val}`,
            });
        }
    }
    return result;
}


export function DebugErr(props: {
    label: string,
    err: unknown | null
}): JSX.Element {
    return <div hidden={props.err === null}>
        {/* Show an Error object: */}
        {props.label}: <code>{errMsg(props.err)}</code>
        <ol>
            <For each={stackLines(props.err)}>
                {line => <li><pre>{line}</pre></li>}
            </For>
        </ol>
        <ul>
            <For each={otherProps(props.err)}>
                {prop => <li>
                    <pre>{prop.key}: {prop.val}</pre>
                </li>}
            </For>
        </ul>
    </div>;
}
