import { createEffect, createMemo, createSignal, For, onCleanup } from 'solid-js';
import { JSX } from 'solid-js/jsx-runtime';

import { Context } from '@bitiotic/bitiotic';
import { Game, Persistent, Round } from '@bitiotic/numbet';
import { GamePlayerListener, StyledName, StyledPNum } from './GamePlayerListener';

interface PlayerView {
    playerPath: Persistent.PlayerPath,
    currentPlayer: boolean,
    active: boolean,
}

interface PlayerViewMap {
    [playerPath: string]: PlayerView,
}

/** meant for a box-shadow CSS stanza on some element for the "current user" */
export const currentPlayerGlowShadow = '0 0 6px 2px #eab308';
export const currentPlayerGlowRadius = '4px';

export function PlayersList(props: {
    ctx: Context,
    currentPlayerPath: Persistent.PlayerPath,
    gamePlayers: Readonly<Persistent.PlayerPath[]> | null,
    game: Game,
    round: Round | null,
    onManagerDevice: boolean,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {

    const [getPlayersView, setPlayersView] = createSignal<PlayerView[]>([]);

    createEffect(() => {
        const ctx: Context = props.ctx.newCtx('PlayersList.fx');
        const playersMap: PlayerViewMap = {};

        const gp = props.gamePlayers;
        if (gp) {
            gp.forEach(playerPath => {
                playersMap[playerPath] = {
                    playerPath,
                    currentPlayer: (playerPath === props.currentPlayerPath),
                    active: false, // in the current round
                };
            });

            const r = props.round;
            if (r) {
                r.getCachedPlayerPaths(ctx).forEach(playerPath => {
                    const v = playersMap[playerPath];
                    ctx.assertDefined(v,
                        'Player must be on Round', playerPath, r.getRoundPath(ctx));
                    v.active = true;
                });
            }
        }

        // Current player is first
        const p0Props = playersMap[props.currentPlayerPath];
        const playersView: PlayerView[] = [];
        if (p0Props) {
            playersView.push(p0Props);
        }

        // TODO: include the additional players driven locally

        // Remaining players are in stable OID order.
        Object.keys(playersMap).sort().forEach(playerPath => {
            if (playerPath !== props.currentPlayerPath) {
                const props = playersMap[playerPath];
                ctx.assertDefined(props,
                    'Props for', playerPath, 'disappeared');
                playersView.push(props);
            }
        });

        setPlayersView(playersView);
    });

    function highlightCurrent(playerView: PlayerView): Record<string, string | string> {
        if (props.currentPlayerPath === playerView.playerPath) {
            return {
                'box-shadow': currentPlayerGlowShadow,
                'border-radius': currentPlayerGlowRadius,
            };
        }
        return {};
    }

    return <div class="grid grid-flow-col auto-cols-fr gap-1 grid-rows-3 sm:grid-rows-2 md:grid-rows-1">
        <For each={getPlayersView()}>
            {playerView =>
                <div
                    class="border-2 border-black rounded-lg px-1 mx-2 shadow "
                    style={highlightCurrent(playerView)}>
                    <PlayerView
                        ctx={props.ctx}
                        game={props.game}
                        round={props.round}
                        playerView={playerView}
                        isCurrentPlayer={props.currentPlayerPath === playerView.playerPath}
                        onManagerDevice={props.onManagerDevice}
                        gamePlayerListener={props.gamePlayerListener}
                    />
                </div>
            }
        </For >
    </div >;
}

interface PlayerWinningsView {
    totalWinnings: string,
    bestGuessCount: string,
    exactGuessCount: string,
    winningBetCount: string,
}

const cashFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

function formatWinnings(x: number): string {
    return cashFormatter.format(x);
}

const THUMBS_UP = '\u{1F44D}';
const CHECK_MARK = '\u{2705}';

function PlayerView(props: {
    ctx: Context,
    game: Game,
    round: Round | null,
    playerView: PlayerView,
    isCurrentPlayer: boolean,
    onManagerDevice: boolean,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {

    const [getReadyStr, setReadyStr] = createSignal<string>('');

    // Guess presence only updated for the manager:
    const [getGuessPresent, setGuessPresent] = createSignal<string>('');

    createEffect(() => {
        const ctx = props.ctx.newCtx('PlayerView.fx');
        const playerPath = props.playerView.playerPath;

        const r = props.round;
        if (r) {
            ctx.log('Installing ready listener for', playerPath, 'in round', r.getRoundPath(ctx));
            const unsubReady = r.listenForPlayerReady(ctx, playerPath,
                (ctx, ready) => {
                    ctx.log('Marking player', playerPath, 'readiness to', ready);
                    if (ready) {
                        setReadyStr(THUMBS_UP);
                    } else {
                        setReadyStr('');
                    }
                },
                err => {
                    ctx.log('Ignoring listenForPlayerReady', err);
                });
            onCleanup(() => unsubReady());

            // Only the manager can see other player's inputs
            if (props.onManagerDevice) {
                const unsubPresent = r.listenForPlayerGuessPresence(ctx, playerPath,
                    (ctx, present) => {
                        ctx.log('Marking player', playerPath, 'guess present', present);
                        if (present) {
                            setReadyStr('');
                            setGuessPresent(CHECK_MARK);
                        } else {
                            setGuessPresent('');
                        }
                    },
                    err => {
                        ctx.log('Ignoring listenForPlayerGuessPresence on', playerPath, err);
                    });
                onCleanup(() => {
                    ctx.log('Cleanup listen-for-guess-presence for player', playerPath);
                    unsubPresent();
                });
            }
        } else {
            setReadyStr('');
            setGuessPresent('');
        }
    });

    const getWinnings = createMemo<PlayerWinningsView>(() => {
        const getter = props.gamePlayerListener.getWinningsSignal(props.ctx, props.playerView.playerPath);
        const gamePlayer = getter();
        if (gamePlayer === null) {
            return {
                totalWinnings: formatWinnings(0),
                bestGuessCount: '0',
                exactGuessCount: '0',
                winningBetCount: '0',
            };
        } else {
            return {
                totalWinnings: formatWinnings(gamePlayer.winnings),
                bestGuessCount: String(gamePlayer.bestGuessCount),
                exactGuessCount: String(gamePlayer.exactGuessCount),
                winningBetCount: String(gamePlayer.winningBetCount),
            };
        }
    });

    return <div class="p-1">
        <div class="space-x-1 flex flex-row">
            <StyledName
                ctx={props.ctx}
                playerPath={props.playerView.playerPath}
                gamePlayerListener={props.gamePlayerListener}
            />
            <span class="grow"></span>
            <StyledPNum
                ctx={props.ctx}
                playerPath={props.playerView.playerPath}
                gamePlayerListener={props.gamePlayerListener}
            />
        </div>
        <div class="flex">
            <span>{getReadyStr()} {getGuessPresent()}</span>
            <span class="grow">&nbsp;</span>
            <span class="font-bold">{getWinnings().totalWinnings}</span>
        </div>
        {/* Hide this detail for now ...*/}
        <details class="hidden">
            <summary>Guesses</summary>
            <table class="text-sm">
                <tbody>
                    <tr>
                        <td>Exact: {getWinnings().exactGuessCount}</td>
                    </tr>
                    <tr>
                        <td>Winning: {getWinnings().bestGuessCount}</td>
                    </tr>
                    <tr>
                        <td>Correct bets: {getWinnings().winningBetCount}</td>
                    </tr>
                </tbody>
            </table>
        </details>
    </div >;
}