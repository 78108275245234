import { Context, FirebaseV9 } from '@bitiotic/bitiotic';
import { Lobby } from '@bitiotic/numbet';
import { Accessor, createMemo, createSignal, JSX, Match, Setter, Show, Switch } from 'solid-js';


// This is too much ...
type AuthState = 'Unknown' | 'SigningIn' | 'SigningOut' | 'SignedIn' | 'SignedOut' | 'IncompleteSignup';

const [getAuthState, setAuthState] = createSignal<AuthState>('Unknown');
const [getShowSignOut, setShowSignOut] = createSignal(true);
const [getShowSignIn, setShowSignIn] = createSignal(true);
export { getAuthState };

type ChangedUserCB = (ctx: Context, u: FirebaseV9.FirebaseUser | null) => Promise<boolean>;

/**
 * Update Auth signals to be consistent with given Auth State.
 */
function updateAuthState(newState: AuthState): void {
    setAuthState(newState);
    setShowSignIn(newState !== 'SignedIn');
    setShowSignOut(newState !== 'SignedOut');
}

interface UserCreds {
    username: string,
    password: string,
}

/**
 * Inside emulator create given user (as necessary).
 * In deployed setup, just pretend user is created.
 * Idempotent.
 */
function emulatorCreateAccount(
    ctx: Context,
    auth: FirebaseV9.FirebaseAuth,
    creds: UserCreds): Promise<FirebaseV9.FirebaseUser | 'exists'> {
    if (auth.usingEmulator()) {
        ctx.log('Creating (emulator) account', creds.username);
        return auth.createUser(ctx, creds.username, creds.password);
    } else {
        return Promise.resolve('exists');
    }
}

/**
 * Sign-out currently logged in user (if any).
 */
function firstSignOut(ctx: Context, fbAuth: FirebaseV9.FirebaseAuth): Promise<unknown> {
    const cu = fbAuth.currentUser();

    if (cu) {
        ctx.log('Already signed in as', cu.uid());
        return signOut(ctx.newCtx('duringSignIn'), fbAuth);
    } else {
        return Promise.resolve();
    }
}

/**
 * Attempt to sign in given user.
 */
function signInUserPassword(ctx: Context,
    lobby: Lobby,
    fbAuth: FirebaseV9.FirebaseAuth,
    creds: FirebaseV9.UserPassword): Promise<unknown> {

    ctx = ctx.newCtx('pass');

    return firstSignOut(ctx, fbAuth).then(() => {
        updateAuthState('SigningIn');

        ctx.log('Signing in', creds.username, '...');

        return emulatorCreateAccount(ctx.newCtx('createAccount'), fbAuth, creds)
            .then(_created => {
                return fbAuth.signInUserPassword(ctx, creds.username, creds.password)
                    .then(user => {
                        ctx.log('Signed in (to Firebase) as', user.email);
                        return lobby.createOrUpdatePlayer(ctx.newCtx('lobbyCreate'), user)
                            .then(() => recheckSignedIn(ctx, fbAuth, user));
                    });
            })
            .catch((err: unknown) => {
                ctx.log('error: signIn with password:', err);
                /*async*/ signOut(ctx, fbAuth);
                throw err;
            });
    });
}

function createNewUserPassword(
    ctx: Context,
    auth: FirebaseV9.FirebaseAuth,
    creds: FirebaseV9.UserPassword): Promise<unknown> {
    return firstSignOut(ctx, auth).then(() => {
        return auth.createUser(ctx, creds.username, creds.password).then(result => {
            ctx.log('Create user:', result);
        });
    });
}

/**
 * Attempt Goog sign-in flow.
 */
function signInWithGoog(ctx: Context,
    lobby: Lobby,
    fbAuth: FirebaseV9.FirebaseAuth): Promise<unknown> {

    ctx = ctx.newCtx('goog');

    return firstSignOut(ctx, fbAuth).then(() => {
        updateAuthState('SigningIn');
        ctx.log('Signing in with Google ...');

        return fbAuth.signInWithGoog(ctx)
            .then(user => {
                ctx.log('Signed in (to Firebase) as', user.email)
                return lobby.createOrUpdatePlayer(ctx.newCtx('lobbyCreate'), user)
                    .then(() => recheckSignedIn(ctx, fbAuth, user));
            });
    }).catch((err: unknown) => {
        ctx.log('error: signIn with Google:', err);
        /*async*/ signOut(ctx, fbAuth);
        throw err;
    });
}

export function signOut(ctx: Context, auth: FirebaseV9.FirebaseAuth): Promise<unknown> {
    ctx.log('Signing out... getAuthState=', getAuthState(), 'current user', auth.currentUser());

    updateAuthState('SigningOut');

    // XXX timeout and ... leave as signed-in?  Ah, wait for onAuthStateChanged to signal
    // rename this "requestSignOut"?

    if (auth.currentUser() !== null) {
        return auth.signOutCurrent(ctx)
            .catch(err => {
                ctx.log('Error signing out:', err);
                updateAuthState('SignedOut');
            });
    } else {
        return recheckSignedIn(ctx.newCtx('signout'), auth, null);
    }
}

// Callback to run Numbets player verification (when FB user is changed).
let changedUserCB: ChangedUserCB | null = null;

/**
 * Re-check to see if the current signed in user is both "signed in" to Firebase and is
 * a valid user in the Lobby.
 */
function recheckSignedIn(ctx: Context, auth: FirebaseV9.FirebaseAuth, user: FirebaseV9.FirebaseUser | null): Promise<unknown> {
    ctx.assertNotNull(changedUserCB);

    if (!user) {
        // No user is signed in.
        updateAuthState('SignedOut');
        ctx.log('recheckSignedIn: Signed out!');
        return changedUserCB(ctx, null);
    } else {
        ctx = ctx.newCtx('recheck');
        return changedUserCB(ctx, user)
            .then(isOk => {
                if (isOk) {
                    updateAuthState('SignedIn');
                } else {
                    updateAuthState('IncompleteSignup');
                }
            })
            .catch(err => {
                ctx.log('Sign-in error:', err);
                /*async*/ signOut(ctx, auth);
            });
    }
}

export function init(ctx: Context, auth: FirebaseV9.FirebaseAuth, cb: ChangedUserCB): void {
    changedUserCB = cb;
    auth.onAuthStateChanged(ctx, user => {
        recheckSignedIn(ctx, auth, user);
    });
}

export function Signup(): JSX.Element {
    return <div>Auth Signup</div>;
}

export function AuthButton(props: {
    onClick: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>,
    hidden: boolean,
    children: JSX.Element,
}): JSX.Element {
    return <button
        class="mx-2 my-1 px-4 py-1 border rounded border-black font-bold hover:bg-black hover:text-gray-100"
        onClick={props.onClick}
        hidden={props.hidden}
    >{props.children}</button>;
}

export function SignInWithGoogleButton(props: {
    onClick: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>,
    hidden: boolean,
}): JSX.Element {
    return <AuthButton
        hidden={props.hidden}
        onClick={props.onClick}
    >Sign In With Google</AuthButton>;
}

function validateUserNamePassword(
    ctx: Context,
    username?: string,
    password?: string): FirebaseV9.UserPassword | undefined {
    if (!username || username.length < 2) {
        ctx.log('invalid username:', username);
        return undefined;
    }
    if (!password || password.length < 1) {
        ctx.log('invalid password', password);
        return undefined;
    }
    return { username, password };
}

function AuthSection(props: {
    title: string,
    children: JSX.Element,
}): JSX.Element {
    return <div class="m-3 border p-2">
        <p class="text-large font-bold">{props.title}</p>
        {props.children}
    </div>;
}

/**
 * In case the user is logged into Firebase correctly,
 * but doesn't have an account in Numbets database.
 */
function finishIncompleteSignup(
    ctx: Context,
    lobby: Lobby,
    auth: FirebaseV9.FirebaseAuth): Promise<unknown> {

    ctx = ctx.newCtx('finish-sign-in');

    const cu = auth.currentUser();
    if (cu === null) {
        // ugh, messed up, just reset
        ctx.log('Current user is null now?  Just sign out and try again ...');
        return signOut(ctx, auth);
    }

    ctx.log('Completing user creation ...');
    return lobby.createOrUpdatePlayer(ctx, cu)
        .then(() => recheckSignedIn(ctx, auth, cu));
}

function AuthErrMsg(props: {
    ctx: Context,
    getErrMsg: Accessor<string>,
}): JSX.Element {
    const nomsg = createMemo<boolean>(() => props.getErrMsg() === '');

    return <p class="mx-1">
        <Show when={nomsg() === true}>
            <span></span>
        </Show>
        <Show when={nomsg() === false}>
            <span class="text-red-900 border-2 border-red-300">{props.getErrMsg()}</span>
        </Show>
    </p>;

}


function LoginWithGoogle(props: {
    ctx: Context,
    auth: FirebaseV9.FirebaseAuth,
    lobby: Lobby,
    getErrMsg: Accessor<string>,
    setErrMsg: Setter<string>,
}): JSX.Element {

    return <AuthSection title="Sign In With Google">
        <p class="mx-1">
            Sign in to Numbets at
            {' '}<a href="https://firebase.google.com/">Google's Firebase</a>
            {' '}(hosted by <span class="font-mono">numbets-92cc6.firebaseapp.com</span>).
        </p>

        <AuthErrMsg ctx={props.ctx.newCtx('errmsg')} getErrMsg={props.getErrMsg} />
        <SignInWithGoogleButton
            hidden={false}
            onClick={() => {
                props.setErrMsg('');
                /*async*/
                signInWithGoog(props.ctx, props.lobby, props.auth).then(() => {
                    props.setErrMsg('');
                }).catch(err => {
                    props.ctx.log('Error signing in:', err);
                    props.setErrMsg('Login to Numbets failed.');
                });
            }}
        />
    </AuthSection>;
}

function LoginWithEmail(props: {
    ctx: Context,
    auth: FirebaseV9.FirebaseAuth,
    lobby: Lobby,
    getErrMsg: Accessor<string>,
    setErrMsg: Setter<string>,
}): JSX.Element {
    const [getPasswdUI, setPasswdUI] = createSignal<string>('');
    const [getUsernameUI, setUsernameUI] = createSignal<string>('');

    return <AuthSection title="Sign In With Email">
        <AuthErrMsg ctx={props.ctx.newCtx('errmsg')} getErrMsg={props.getErrMsg} />
        <form class="rounded px-8 pt-6 pb-8 mb-4">
            <label
                class="block mb-2 text-sm font-medium"
                for="username-input">Email</label>
            <input
                id="username-input"
                autocomplete="email"
                required
                minlength="2"
                maxlength="256"
                class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline invalid:border-red-600"
                type="text"
                value={getUsernameUI()}
                onInput={ev => { setUsernameUI(ev.target.value); }}></input>
            <label
                class="block mb-2 text-sm font-medium"
                for="password-input">Password</label>
            <input type="password"
                value=""
                class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline invalid:border-red-600"
                autocomplete="current-password"
                minlength="1"
                maxlength="256"
                id="password-input"
                onInput={ev => { setPasswdUI(ev.target.value); }}></input>
        </form>

        <AuthButton
            onClick={() => {
                const creds = validateUserNamePassword(props.ctx, getUsernameUI(), getPasswdUI());
                if (creds) {
                    props.setErrMsg('');
                    /* async */
                    signInUserPassword(props.ctx, props.lobby, props.auth, creds)
                        .catch(err => {
                            props.setErrMsg(`${err}`);
                        });
                } else {
                    props.setErrMsg('Invalid email or password (and poor error handling)');
                }
            }}
            hidden={!getShowSignIn()} >Sign In With Email</AuthButton>
    </AuthSection>;
}

function CreateEmailLogin(props: {
    ctx: Context,
    auth: FirebaseV9.FirebaseAuth,
    lobby: Lobby,
    getErrMsg: () => string,
    setErrMsg: Setter<string>,
}): JSX.Element {
    const [getPasswdUI, setPasswdUI] = createSignal<string>('');
    const [getUsernameUI, setUsernameUI] = createSignal<string>('');

    return <AuthSection title="Create Email Account">
        <AuthErrMsg ctx={props.ctx.newCtx('errmsg')} getErrMsg={props.getErrMsg} />

        <form class="rounded px-8 pt-6 pb-8 mb-4">
            <label
                class="block mb-2 text-sm font-medium"
                for="new-username-input">Email</label>
            <input
                id="new-username-input"
                autocomplete="email"
                required
                minlength="2"
                maxlength="256"
                class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline invalid:border-red-600"
                type="text"
                value={getUsernameUI()}
                onInput={ev => { setUsernameUI(ev.target.value); }}></input>
            <label
                class="block mb-2 text-sm font-medium"
                for="new-password-input">Password</label>
            <input type="password"
                value=""
                class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline invalid:border-red-600"
                autocomplete="new-password"
                minlength="1"
                maxlength="256"
                id="new-password-input"
                onInput={ev => { setPasswdUI(ev.target.value); }}></input>
        </form>

        <AuthButton
            onClick={() => {
                const creds = validateUserNamePassword(props.ctx, getUsernameUI(), getPasswdUI());
                if (creds) {
                    props.setErrMsg('');
                    /*async*/
                    createNewUserPassword(props.ctx, props.auth, creds).catch(err => {
                        props.setErrMsg(`${err}`);
                    });
                } else {
                    props.setErrMsg('Invalid email or password (and poor error handling)');
                }
            }}
            hidden={!getShowSignIn()} >Create Account</AuthButton>
    </AuthSection>;
}

export function Login(props: {
    ctx: Context,
    auth: FirebaseV9.FirebaseAuth,
    lobby: Lobby,
}): JSX.Element {
    const [getLoginStateMsg, setLoginStateMsg] = createSignal<string>('');
    const [getCreateStateMsg, setCreateStateMsg] = createSignal('');
    const [getGoogLoginStateMsg, setGoogLoginStateMsg] = createSignal('');

    return <Switch fallback={<div>Loading login ...</div>}>
        <Match when={getAuthState() === 'Unknown'}>
            <div>
                Login status unclear, trying to establish ...
            </div>
        </Match>
        <Match when={getAuthState() === 'SigningIn'}>
            <span class="font-light italic">signing in ...</span>
        </Match>
        <Match when={getAuthState() === 'IncompleteSignup'}>
            <div>
                <div>
                    Sign-up is incomplete ... just wait a moment and let the internet servers catch up.
                </div>

                <div>
                    However, if you're stuck here for a while, this button might fix things:
                </div>
                <AuthButton
                    onClick={() => finishIncompleteSignup(props.ctx, props.lobby, props.auth)}
                    hidden={false}
                >Complete Sign-on</AuthButton>
            </div>
        </Match>
        <Match when={getAuthState() === 'SigningOut'}>
            <span class="font-light italic">signing out ...</span>
        </Match>
        <Match when={getAuthState() === 'SignedIn'}>
            <div>
                Logged in.
            </div>
            <AuthButton
                onClick={() => signOut(props.ctx, props.auth)}
                hidden={!getShowSignOut()}>Sign-out</AuthButton>
        </Match>
        <Match when={getAuthState() === 'SignedOut'}>
            <LoginWithGoogle ctx={props.ctx.newCtx('LoginGoog')} auth={props.auth} lobby={props.lobby} getErrMsg={getGoogLoginStateMsg} setErrMsg={setGoogLoginStateMsg} />
            <LoginWithEmail ctx={props.ctx.newCtx('LoginEmail')} auth={props.auth} lobby={props.lobby} getErrMsg={getLoginStateMsg} setErrMsg={setLoginStateMsg} />
            <CreateEmailLogin ctx={props.ctx.newCtx('CreateLogin')} auth={props.auth} lobby={props.lobby} getErrMsg={getCreateStateMsg} setErrMsg={setCreateStateMsg} />
        </Match>
    </Switch>;
}
