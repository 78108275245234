import { render } from 'solid-js/web';
import { createSignal, Switch, Match, JSX, createResource, JSXElement } from 'solid-js';

import { Context, FirebaseV9 } from '@bitiotic/bitiotic';
import { Game, Lobby, Persistent } from '@bitiotic/numbet';

import * as auth from './auth';
import { DebugObj } from './DebugObj';
import { GameBoard } from './GameBoard';
import { Route, Link, init as routingInit, getRawRouteParams, getRouteGamePath, routeTo } from './Routing';
import { GameList } from './GameList';

import './index.css';
import { GameConnectionState } from '@bitiotic/numbet/src/Game';
import { NewGame, getGameURL } from './NewGame';
import { StandardButton } from './StandardButton';
import { AccountSettings } from './Account';
import { About } from './About';

const globalCtx = new Context('numbet-client');

// XXX do this elsewhere?
Error.stackTraceLimit = 250;

// Detect if we should use the Firebase emulator,
// or connect to the "real" server (whose configuration)
function usingEmulator() {
    const hn = window?.location?.hostname;
    const useEmu = (hn === undefined)
        || hn.startsWith('cerberus')
        || hn.startsWith('linhex');

    const emuStr = useEmu ? 'emulator' : 'Firestore';
    if (process.env.NODE_ENV !== 'production') {
        globalCtx.log('Build: debug,', emuStr);
    } else {
        globalCtx.log('Build: prod,', emuStr);
    }

    return useEmu;
}

const firebase = FirebaseV9.connectWeb(globalCtx, usingEmulator());

const lobby = new Lobby(globalCtx, firebase.firestore, firebase.funcClient);

const [getCurrentPlayerPath, setCurrentPlayerPath] = createSignal<Persistent.PlayerPath | 'unknown'>('unknown');

routingInit(globalCtx);

auth.init(globalCtx, firebase.auth, (chCtx, newUser) => {
    if (newUser === null) {
        setCurrentPlayerPath('unknown');
        return Promise.resolve(false);
    } else {
        return lobby.isValidUser(chCtx, newUser).then(isOk => {
            if (isOk) {
                const playerPath = Lobby.pathForUser(newUser);
                setCurrentPlayerPath(playerPath);
            }
            return isOk;
        });
    }
});


function fetchCurrentGamePath(
    ctx: Context,
    currentPlayer: Persistent.PlayerPath | 'unknown'): Promise<Persistent.GamePath | 'unknown' | null> {
    if (currentPlayer === 'unknown') {
        return Promise.resolve('unknown');
    }

    return lobby.getCurrentGame(ctx, currentPlayer);
}

function rerouteToCurrentGame(ctx: Context, gamePath: Persistent.GamePath | 'unknown' | null | undefined): JSXElement {

    if (gamePath === undefined || gamePath === null || gamePath === 'unknown') {
        ctx.log('Ignoring invalid gamePath', gamePath);
        return <div></div>;
    }

    ctx.log('rerouting from /game/current to /game/' + gamePath);
    routeTo(ctx, 'game', { gamePath: gamePath ?? 'current' });

    return <div>Rerouting to {gamePath}... </div>;
}

function NavBar(props: {
    ctx: Context,
}): JSX.Element {
    return <div>
        <Link ctx={props.ctx} ref="default">Home</Link>&nbsp;|&nbsp;
        <Link ctx={props.ctx} ref="about">About</Link>&nbsp;|&nbsp;
        <Link ctx={props.ctx} ref="account">Account</Link>&nbsp;|&nbsp;
        <Link ctx={props.ctx} ref="games">Games</Link>&nbsp;|&nbsp;
        <Link ctx={props.ctx} ref="newgame">New Game</Link>
    </div>;
}

function waitForJoinAccept(ctx: Context, db: FirebaseV9.Firestore,
    gamePath: Persistent.GamePath, playerPath: Persistent.PlayerPath): Promise<Game> {
    return new Promise<Game>((resolve, reject) => {
        const cancelCb = Game.listenForJoinAccept(ctx.newCtx('joinListen'), db, gamePath, playerPath, playerPath,
            (game) => {
                // why might game be null again?
                if (game !== null) {
                    cancelCb();
                    resolve(game);
                }
            },
            err => {
                cancelCb();
                reject(err);
            },
        );
    });
}

function GameHeader(props: {
    ctx: Context,
    gamePath: Persistent.GamePath | null,
}): JSX.Element {
    return <div class="font-bold text-xl">
        Numbets (<a
            class="bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white"
            href={getGameURL(props.ctx, props.gamePath)}>game</a>)
    </div>;
}

function App(): JSXElement {
    function fetchGameAndPlayer() {
        return {
            gamePath: getRouteGamePath(),
            playerPath: getCurrentPlayerPath(),
        };
    }

    // Create Game resource from a game path
    const [getRouteGame] = createResource(fetchGameAndPlayer, ({ gamePath, playerPath }): Promise<Game | 'none'> => {

        const db = firebase.firestore;

        const rCtx = globalCtx.newCtx('loadRouteGame');
        rCtx.log('createResource: game from path', gamePath);

        const NO_GAME = Promise.resolve<'none'>('none');

        if (playerPath === 'unknown') {
            rCtx.log('No current player yet ...');
            return NO_GAME;
        }

        if (gamePath === null) {
            rCtx.log('No gamePath yet ...');
            return NO_GAME;
        }

        const joinStateProm = Game.getGameJoinState(rCtx, db, gamePath, playerPath);

        return joinStateProm.then((joinState: GameConnectionState): Promise<Game | 'none'> => {
            rCtx.log('Game', gamePath, 'joinState:', joinState);
            switch (joinState) {
                case 'nogame':
                    return NO_GAME;
                case 'manager':
                    return Game.loadExisting(rCtx, db, gamePath);
                case 'nojoinreq':
                    // TODO: Collect "secondary" players here
                    return Game.createRequestToJoin(
                        rCtx.newCtx('reqToJoin'), db, gamePath, playerPath, playerPath)
                        .then(() => {
                            return waitForJoinAccept(rCtx, db, gamePath, playerPath);
                        });
                case 'nojoinack':
                    // I have a join request outstanding ..
                    return waitForJoinAccept(rCtx, db, gamePath, playerPath);
                case 'joined': // fall-through
                case 'completed':
                    return Game.loadExisting(rCtx, db, gamePath);
            }
            joinState satisfies never;
            rCtx.assertUnreachable('joinState', joinState);
            return NO_GAME;
        }).catch(err => {
            rCtx.log('Failed to compute joinState:', err);
            return NO_GAME;
        });
    });

    // Map current Player into a current Game Path.
    // The "current" game is just used to highlight the
    // new game after its created.  Otherwise the game being played should come from the URL.
    const [getCurrentGamePath] = createResource(getCurrentPlayerPath,
        playerPath => fetchCurrentGamePath(globalCtx, playerPath));


    // This is the root div of the app.
    //
    // https://stackoverflow.com/questions/53487190/why-is-chrome-shrinking-the-view-in-responsive-mode

    return <div class="m-1 sm:m-3 md:m-5 width-full" >
        <NavBar ctx={globalCtx} />

        <Route ctx={globalCtx} fbauth={firebase.auth} lobby={lobby} when='default' authRoute='public'>
            Welcome to Numbets.
        </Route>
        <Route ctx={globalCtx} fbauth={firebase.auth} lobby={lobby} when='loading' authRoute='public'>
            Loading ...
        </Route>
        <Route ctx={globalCtx} fbauth={firebase.auth} lobby={lobby} when='about' authRoute='public'>
            <About ctx={globalCtx} />
        </Route>
        <Route ctx={globalCtx} fbauth={firebase.auth} lobby={lobby} when='account' authRoute='auth'>
            <AccountSettings ctx={globalCtx.newCtx('acct')} fbauth={firebase.auth} lobby={lobby} />
        </Route>
        <Route ctx={globalCtx} fbauth={firebase.auth} lobby={lobby} when='signup' authRoute='public'>
            <auth.Signup />
        </Route>
        <Route ctx={globalCtx} fbauth={firebase.auth} lobby={lobby} when='err' authRoute='public'>
            Route error!
            <DebugObj
                _closed={false}
                _visible={true}
                params={getRawRouteParams()}
                currentFBUser={firebase.auth.currentUser()}
                location={window.location}
            />
        </Route>
        <Route ctx={globalCtx} fbauth={firebase.auth} lobby={lobby} when='games' authRoute='auth'>
            <GameList ctx={globalCtx.newCtx('gamelist')} lobby={lobby} firestore={firebase.firestore}
                currentGamePath={getCurrentGamePath()}
                playerPath={getCurrentPlayerPath()} />
        </Route>
        <Route ctx={globalCtx.newCtx('newgame')} fbauth={firebase.auth} lobby={lobby} when='newgame' authRoute='auth'>
            <NewGame
                ctx={globalCtx.newCtx('newGame')} lobby={lobby} firestore={firebase.firestore}
                playerPath={getCurrentPlayerPath()}
            />
        </Route>
        <Route ctx={globalCtx.newCtx('game')} fbauth={firebase.auth} lobby={lobby} when='game'>
            <Switch fallback={<div>Loading...</div>}>
                <Match when={getRouteGamePath() === 'current'}>
                    {rerouteToCurrentGame(globalCtx, getCurrentGamePath())}
                </Match>
                <Match when={getRouteGame.state !== 'ready'}>
                    (Waiting for routed game update ...)

                    <DebugObj
                        _closed={true}
                        _visible={false}
                        route={getRouteGamePath()}
                        currRouteState={getRouteGame.state}
                        currRouteLoading={getRouteGame.loading}
                        currRouteError={getRouteGame.error}
                        currRouteLatest={getRouteGame.latest}
                    />
                </Match>
                <Match when={getRouteGame.state === 'ready'}>
                    <Switch>
                        <Match when={getRouteGame() === 'none'}>
                            <div class="p-3">No such game "{getRouteGamePath()}".</div>
                            <StandardButton
                                onClick={() => routeTo(globalCtx, 'newgame')}
                                color="green"
                            >New Game</StandardButton>
                        </Match>
                        <Match when={true}>
                            <GameHeader
                                ctx={globalCtx.newCtx('header')}
                                gamePath={getRouteGamePath()}
                            />
                            <GameBoard
                                lobby={lobby}
                                ctx={globalCtx}
                                playerPath={getCurrentPlayerPath()}
                                game={getRouteGame() as Game}
                            />
                        </Match>
                    </Switch>
                </Match>
            </Switch>
        </Route>
    </div>;
}

render(App, document.body);