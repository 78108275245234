
import { JSX } from 'solid-js/jsx-runtime';

export function StandardButton(props: {
    onClick: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>,
    disabled?: boolean,
    children: JSX.Element
    color: 'green' | 'red',
}): JSX.Element {
    const common = 'font-semibold py-1 px-2 mx-2 border-2 rounded bg-transparent';
    const activeR = ' border-red-800 text-red-700 hover:border-transparent hover:bg-red-300 hover:text-red-500 ';
    const activeG = ' border-green-800 text-green-700 hover:border-transparent hover:bg-green-700 hover:text-white ';
    const inact = ' text-stone-700 hover:text-stone border-stone-500';

    const active = (props.color === 'red') ? activeR : activeG;

    return <button
        class={common + (props.disabled ? inact : active)}
        disabled={props.disabled}
        onClick={props.onClick}
    >{props.children}</button>;
}

