import { Context } from '@bitiotic/bitiotic';
import { JSX } from 'solid-js/jsx-runtime';

/*
 * A "sticky" header that will stick to the top or the bottom.  Header is sticky only to its
 * containing div.
 */
export function SectionHeader(props: {
    ctx: Context,
    children: JSX.Element,
}): JSX.Element {
    return <div class="p-3 w-100 bg-blue-100 font-bold m-2 sticky bottom-0 top-0 z-30">
        {props.children}
    </div>;
}
