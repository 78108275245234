/**
 * Service for tracking updates to players in a Game.
 */

import { Context } from '@bitiotic/bitiotic';
import { Game, ListenErrorCallback, Lobby, Persistent, PlayerColors, UNASSIGNED_COLOR } from '@bitiotic/numbet';
import { Accessor, createSignal, JSX, Signal } from 'solid-js';

export class GamePlayerListener {
    private readonly game: Game;
    private readonly lobby: Lobby;
    private readonly nameSignals: Record<Persistent.PlayerPath, Signal<string>> = {};
    private readonly activeSignals: Record<Persistent.PlayerPath, Signal<boolean>> = {};
    private readonly winningsSignals: Record<Persistent.PlayerPath, Signal<Persistent.GamePlayer | null>> = {};
    private readonly colorSignals: Record<Persistent.PlayerPath, Signal<string>> = {};
    private readonly numSignals: Record<Persistent.PlayerPath, Signal<number | null>> = {};
    private readonly errCallback: ListenErrorCallback;

    constructor(ctx: Context, lobby: Lobby, game: Game, errCallback: ListenErrorCallback) {
        this.game = game;
        this.lobby = lobby;
        this.errCallback = errCallback;
    }

    private watchPlayer(ctx: Context, playerPath: Persistent.PlayerPath): {
        nameSignal: Signal<string>,
        numSignal: Signal<number | null>,
        activeSignal: Signal<boolean>,
        winningsSignal: Signal<Persistent.GamePlayer | null>,
        colorSignal: Signal<string>,
    } {
        const defaultName = playerPath.slice(0, 6) + '...';

        const nameSignal = createSignal<string>(defaultName);
        this.nameSignals[playerPath] = nameSignal;
        const setName = nameSignal[1];

        const activeSignal = createSignal<boolean>(false);
        this.activeSignals[playerPath] = activeSignal;
        const setActive = activeSignal[1];

        const winningsSignal = createSignal<Persistent.GamePlayer | null>(null);
        this.winningsSignals[playerPath] = winningsSignal;
        const setWinnings = winningsSignal[1];

        const numSignal = createSignal<number | null>(null);
        this.numSignals[playerPath] = numSignal;
        const setNum = numSignal[1];

        // Colors aren't dynamic (or even persistent) yet
        const colorSignal = createSignal<string>(UNASSIGNED_COLOR);
        this.colorSignals[playerPath] = colorSignal;
        const setColor = colorSignal[1];

        ctx.log('Installing global- and game- listeners for player', playerPath);
        ctx = ctx.newCtx('watchPlayer');
        this.lobby.listenToPlayer(ctx, playerPath,
            playerDoc => {
                ctx.log(`Got name "${playerDoc.displayName}" for player ${playerPath}`);
                setName(playerDoc.displayName);
            },
            err => {
                ctx.log(`Error getting name for player ${playerPath}:`, err);
                this.errCallback(ctx.chainError(err, 'lobby listen player ' + playerPath));
            });

        this.game.listenForGamePlayer(ctx, playerPath,
            gamePlayerDoc => {
                setActive(gamePlayerDoc.isActive);
                setWinnings(gamePlayerDoc);
                const pNum = gamePlayerDoc.playerNum;
                setNum(pNum);

                const colors = Object.keys(PlayerColors);
                ctx.assertGT(pNum, 0); // 1-based
                const color = colors[(pNum - 1) % colors.length] ?? UNASSIGNED_COLOR;
                setColor(color);
            },
            err => {
                this.errCallback(ctx.chainError(err, 'game listen player ' + playerPath));
            });

        return {
            nameSignal,
            numSignal,
            activeSignal,
            winningsSignal,
            colorSignal,
        };
    }

    getNameSignal(ctx: Context, playerPath: Persistent.PlayerPath): Accessor<string> {
        let signal = this.nameSignals[playerPath];
        if (!signal) {
            const { nameSignal } = this.watchPlayer(ctx, playerPath);
            signal = nameSignal;
        }
        return signal[0];
    }

    getActiveSignal(ctx: Context, playerPath: Persistent.PlayerPath): Accessor<boolean> {
        let s = this.activeSignals[playerPath];
        if (!s) {
            const { activeSignal } = this.watchPlayer(ctx, playerPath);
            s = activeSignal;
        }
        return s[0];
    }

    getWinningsSignal(ctx: Context, playerPath: Persistent.PlayerPath): Accessor<Persistent.GamePlayer | null> {
        let s = this.winningsSignals[playerPath];
        if (!s) {
            const { winningsSignal } = this.watchPlayer(ctx, playerPath);
            s = winningsSignal;
        }
        return s[0];
    }

    getColorSignal(ctx: Context, playerPath: Persistent.PlayerPath): Accessor<string> {
        let s = this.colorSignals[playerPath];
        if (!s) {
            const { colorSignal } = this.watchPlayer(ctx, playerPath);
            s = colorSignal;
        }
        return s[0];
    }

    getNumSignal(ctx: Context, playerPath: Persistent.PlayerPath): Accessor<number | null> {
        let s = this.numSignals[playerPath];
        if (!s) {
            const { numSignal } = this.watchPlayer(ctx, playerPath);
            s = numSignal;
        }
        return s[0];
    }
}

export function StyledName(props: {
    ctx: Context,
    playerPath: Persistent.PlayerPath,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {

    function getName(): string {
        const getter = props.gamePlayerListener.getNameSignal(props.ctx, props.playerPath);
        return getter();
    }

    function getColor(): string {
        const getter = props.gamePlayerListener.getColorSignal(props.ctx, props.playerPath);
        return getter();
    }

    // Use a style= to set the color, as its dynamic, and tailwind doesn't support
    // classname composition: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
    //
    // min-w-12: give a small minimum so it will shrink if necessary
    //
    return <span
        class="font-bold truncate shrink grow min-w-12 p-1"
        style={{ color: getColor() }}
    >{getName()}</span>;
}

export function StyledPNum(props: {
    ctx: Context,
    playerPath: Persistent.PlayerPath,
    gamePlayerListener: GamePlayerListener,
}): JSX.Element {
    function getPNum(): string {
        const getter = props.gamePlayerListener.getNumSignal(props.ctx, props.playerPath);
        const pNum = getter();
        if (pNum === null) {
            return 'P?';
        } else {
            return 'P' + pNum;
        }
    }

    function getColor(): string {
        const getter = props.gamePlayerListener.getColorSignal(props.ctx, props.playerPath);
        return getter();
    }

    // Use a style= to set the color, as its dynamic, and tailwind doesn't support
    // classname composition: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
    return <span
        class="font-bold p-1"
        style={{
            color: getColor(),
        }}>{getPNum()}</span>;
}