/*
 * Home-grown page routing.
 */

import { createEffect, createSignal, Switch, Match, Show, createMemo } from 'solid-js';
import { JSX } from 'solid-js/jsx-runtime';

import { Context, FirebaseV9 } from '@bitiotic/bitiotic';
import { Lobby, Persistent } from '@bitiotic/numbet';

import * as auth from './auth';
import { DebugObj } from './DebugObj';

// Map from route name to URL
const RouteMap = {
    'default': '',
    'signup': 'signup',
    'account': 'account',
    'logout': 'logout',
    'about': 'about',
    'err': 'err',
    'loading': '', // pseudo route to indiciate still loading
    'games': 'games',
    'newgame': 'game/new',
    // XXX 'currentgame': 'game/current', ?
    'game': 'game/:gamePath',
} as const;

type ParsedRoute = keyof typeof RouteMap;

// Names of URL paths that are "simple" and map directly to a ParsedRoute
const SIMPLE_PATHS = [
    'signup', 'account', 'logout', 'about', 'games', 'err',
] as const;

type PathParams = Record<string, string | undefined>;

/**
 * Pull the logical route and any route-specific parameters out of a URL.
 */
interface ParsedRouteResult {
    parsedRoute: ParsedRoute,
    params: PathParams,
}

export const [getGlobalPagePath, setGlobalPagePath] = createSignal<string>('');
export const [getGlobalParsedRoute, setGlobalParsedRoute] = createSignal<ParsedRoute>('loading');
export const [getRouteGamePath, setRouteGamePath] = createSignal<Persistent.GamePath | null>(null);
export const [getQueryParams, setQueryParams] = createSignal<URLSearchParams | null>(null);
export const [getRawRouteParams, setRawRouteParams] = createSignal<PathParams | null>(null);

/**
 * "Simple" means no required parameters are expected in the URL
 */
function isSimpleRoute(s: string): s is typeof SIMPLE_PATHS[number] {
    return SIMPLE_PATHS.includes(s as typeof SIMPLE_PATHS[number]);
}

/**
 * Get URL for the current browser location
 */
function currentURL(ctx: Context): URL {
    try {
        return new URL(window.location.href);
    } catch (err) {
        throw ctx.chainError(err, `URL for "${window.location.href}"`);
    }
}

export function init(ctx: Context): void {
    const initPath = currentURL(ctx);
    // Set Router state to reflect the initial URL browser is loading at
    setAppRouteFromUrl(ctx, initPath.pathname, initPath.searchParams);

    // Install popState handler to trap "Back" button clicks
    const popCtx = ctx.newCtx('popstate');
    window.addEventListener('popstate', popEvent => {
        const curPath = currentURL(ctx);
        const historyState = popEvent.state; // state saved by history.pushState

        ctx.log('popstate: navigating to', curPath.pathname, '; pushstate:', historyState);
        // window.location was updated by the browser and is correct
        setAppRouteFromUrl(popCtx, curPath.pathname, curPath.searchParams);
    });
}

// Set (global) router state to reflect the given URL path
function setAppRouteFromUrl(ctx: Context, path: string, queryParams: URLSearchParams | null): void {
    const { parsedRoute, params } = parseRoute(ctx, path);

    ctx.log('Location', path, 'maps to', parsedRoute, 'and', params);

    setGlobalPagePath(path);
    setGlobalParsedRoute(parsedRoute);
    setQueryParams(queryParams);
    setRawRouteParams(params);

    if (parsedRoute === 'game') {
        const gp = params.gamePath;
        if (gp) {
            setRouteGamePath(Persistent.asGamePath(gp));
        } else {
            setRouteGamePath(null);
        }
    }
}

/**
 * Parse given URL path (just the path part, no protocol or host).  Assumes
 * Path is "canonical" (no duplicated /, etc)?
 */
function parseRoute(ctx: Context, path: string): ParsedRouteResult {
    function routeOnly(parsedRoute: ParsedRoute): ParsedRouteResult {
        return {
            parsedRoute,
            params: {},
        };
    }

    function routeErr(details: PathParams): ParsedRouteResult {
        return {
            parsedRoute: 'err',
            params: details,
        };
    }

    if (path === undefined) {
        return routeOnly('default');
    }

    if (typeof path !== 'string') {
        ctx.log('Route path is not a string:', path);
        return routeErr({ url: path });
    }

    if (path === '' || path === '/') {
        return routeOnly('default');
    }

    // Some browsers don't start pathname with a '/'?
    if (path[0] === '/') {
        path = path.slice(1);
    }

    const parts = path.split('/');
    const p0 = parts[0];

    if (!p0) {
        ctx.log(`Bad URL "${path}": p0: "${p0}"`);
        return routeErr({ url: path, p0 });
    }

    // Manually parse the "interesting" routes
    if (p0 === 'game') {
        // Extra parts in URL are an error?  Or get ignored?
        if (parts.length > 2) {
            ctx.log('Ignoring extraneous content in URL:', parts);
        }

        const p1 = parts[1];

        if (!p1) {
            // No specific game, redirect "game/" to "games/"
            ctx.log(`Redirect "${path}" to "games"`);
            return routeOnly('games');
        }

        if (p1 === 'new') {
            return routeOnly('newgame');
        }

        return {
            parsedRoute: p0,
            params: {
                gamePath: p1,
            },
        };
    }

    if (isSimpleRoute(p0)) {
        return routeOnly(p0);
    }

    ctx.log(`No known route for "${path}":`, parts);
    return routeErr({ url: path, p0 });
}

/**
 * Convert a parsed route into a URL path.
 *
 * @param route short route name
 * @param params optional parameters to encode in the URL
 * @returns
 */
function pathnameFor(route: ParsedRoute, params: PathParams = {}): string {
    // XXX encode leftover params as query terms?

    if (route === 'game') {
        const gamePath = params.gamePath;
        if (gamePath) {
            return `/game/${gamePath}`;
        }
        return '/game';
    }

    const s = RouteMap[route];
    if (typeof s === 'string') {
        return '/' + s;
    }

    return '/' + RouteMap.err;
}

/**
 * Route the app to the given path.
 */
function routeToPath(ctx: Context, titlePart: string, path: string) {
    const url = new URL(window.location.href);
    url.pathname = path;

    const title = `Numbets: ${titlePart}`;
    const historyState = { title, };

    window.history.pushState(historyState, title, url.href);

    setAppRouteFromUrl(ctx, url.pathname, null);
}

/**
 * Route app to the given logical route
 */
export function routeTo(ctx: Context, route: ParsedRoute, params: PathParams = {}): void {
    const path = pathnameFor(route, params);
    routeToPath(ctx, route, path);
}

/**
 * JSX Link element for given ParsedRoute.
 */
export function Link(props: {
    ctx: Context,
    ref: ParsedRoute,
    children: JSX.Element,
}): JSX.Element {

    const path = pathnameFor(props.ref);
    return <span>
        <a
            class="font-bold text-blue-700 hover:text-blue-400 visited:text-violet-900"
            href={path}
            onClick={
                ev => {
                    routeToPath(props.ctx, props.ref, path);
                    ev.preventDefault();
                }
            }
        >{props.children}</a>
    </span>;
}

/**
 * JSX Link element for a specific game.
 */
export function GameLink(props: {
    ctx: Context,
    gamePath: Persistent.GamePath | null | 'unknown' | undefined,
    children: JSX.Element,
}): JSX.Element {

    const [getPath, setPath] = createSignal<string | null>(null);
    createEffect(() => {
        const gp = props.gamePath;
        if (!gp || (gp === 'unknown')) {
            setPath(null);
        } else {
            setPath(pathnameFor('game', { gamePath: gp }));
        }
    });

    return <a
        class="font-bold text-blue-700 hover:text-blue-400 visited:text-violet-900"
        href={getPath() ?? 'err'}
        onClick={
            ev => {
                const p = getPath();
                if (p !== null) {
                    routeToPath(props.ctx, `game ${p}`, p);
                }
                ev.preventDefault();
            }
        }
    >{props.children}</a>;
}

export type RouteAuth = 'public' | 'auth';

/**
 * Display a Route, when its active.
 */
export function Route(props: {
    ctx: Context,
    fbauth: FirebaseV9.FirebaseAuth,
    lobby: Lobby,
    when: ParsedRoute,
    authRoute?: RouteAuth,
    children: JSX.Element,
}): JSX.Element {
    const requireAuth = props.authRoute ?? 'auth'; // default to authorized only

    // Memoize to help hide transient changes in getAuthState
    const isNotSignedIn = createMemo(() => {
        return auth.getAuthState() !== 'SignedIn';
    });

    return <Show when={getGlobalParsedRoute() === props.when}>
        <DebugObj
            _closed={true}
            _visible={false}
            requireAuth={requireAuth}
            auth={props.authRoute}
            authState={auth.getAuthState()}
            when={props.when}
        />
        <Switch fallback={<div>Route auth render error</div>}>
            <Match when={requireAuth === 'auth' && isNotSignedIn()}>
                <div class="text-xl font-bold">Must be Signed In For <code>/{props.when}/</code> pages.</div>
                <auth.Login ctx={props.ctx} lobby={props.lobby} auth={props.fbauth} />
            </Match>
            <Match when={true}>
                {props.children}
            </Match>
        </Switch>
    </Show>;

}